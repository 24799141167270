// reducers/index.js
import { combineReducers } from 'redux';
import userReducer from './userReducer';
import avatarReducer from './avatarReducer';
import knowledgeBankReducer from './knowledgeBankReducer';
import accountReducer from './accountReducer';
import companyProfilesReducer from './profileReducer';
import currentUserReducer from './authReducer';
import generalKnowledgeBank from './generalknowledgeBankReducer';
import tutorialReducer from './tutorialReducer';
import narrativeReducer from './narrativeReducer';

const rootReducer = combineReducers({
  user: userReducer,
  avatar: avatarReducer,
  knowledgeBank: knowledgeBankReducer,
  companyAccount: accountReducer,
  companyProfiles: companyProfilesReducer,
  currentUser: currentUserReducer,
  generalKnowledgeBank: generalKnowledgeBank,
  tutorials: tutorialReducer,
  narrativeData: narrativeReducer,
});

export default rootReducer;
