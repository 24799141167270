// actions/userActions.js
// import axios from 'axios';
import axiosInstance from '../axiosConfig';

export const fetchAvatarRequest = () => {
    return {
        type: 'FETCH_AVATAR_REQUEST'
    };
};

export const fetchAvatarSuccess = (avatar) => {
    return {
        type: 'FETCH_AVATAR_SUCCESS',
        payload: avatar
    };
};

export const fetchAvatarFailure = (error) => {
    return {
        type: 'FETCH_AVATAR_FAILURE',
        payload: error
    };
};

export const createAvatarRequest = () => {
    return {
        type: 'CREATE_AVATAR_REQUEST'
    };
};

export const createAvatarSuccess = (avatar) => {
    return {
        type: 'CREATE_AVATAR_SUCCESS',
        payload: avatar
    };
};

export const createAvatarFailure = (error) => {
    return {
        type: 'CREATE_AVATAR_FAILURE',
        payload: error
    };
};


export const fetchConvaiRequest = () => {
    return {
        type: 'FETCH_CONVAI_REQUEST'
    };
};

export const fetchConvaiSuccess = (characterDetail) => {
    return {
        type: 'FETCH_CONVAI_SUCCESS',
        payload: characterDetail
    };
};

export const fetchConvaiFailure = (error) => {
    return {
        type: 'FETCH_CONVAI_FAILURE',
        payload: error
    };
};

export const createConvaiRequest = () => {
    return {
        type: 'CREATE_CONVAI_REQUEST'
    };
};

export const createConvaiSuccess = (avatar) => {
    return {
        type: 'CREATE_CONVAI_SUCCESS',
        payload: avatar
    };
};

export const createConvaiFailure = (error) => {
    return {
        type: 'CREATE_CONVAI_FAILURE',
        payload: error
    };
};

export const fetchAccentRequest = () => {
    return {
        type: 'FETCH_CONVAI_ACCENTS_LIST'
    };
};

export const fetchAccentSuccess = (accent) => {
    return {
        type: 'FETCH_CONVAI_ACCENTS_LIST_SUCCESS',
        payload: accent
    };
};

export const fetchAccentFailure = (error) => {
    return {
        type: 'FETCH_CONVAI_ACCENTS_LIST_FAILURE',
        payload: error
    };
};

export const fetchAvatar = () => {
    return (dispatch) => {
        dispatch(fetchAvatarRequest());
        axiosInstance.get('/avatars/avatars/')
            .then(response => {
                const avatar = response.data;
                dispatch(fetchAvatarSuccess(avatar));
            })
            .catch(error => {
                const errorMsg = error.message;
                dispatch(fetchAvatarFailure(errorMsg));
            });
    };
};

export const createAvatar = (avatarDetails) => {
    return (dispatch) => {
        dispatch(createAvatarRequest());
        axiosInstance.post('/avatars/avatars/', avatarDetails)
            .then(response => {
                const avatar = response.data;
                dispatch(createAvatarSuccess(avatar));
                dispatch(fetchAvatar());
            })
            .catch(error => {
                const errorMsg = error.message;
                dispatch(createAvatarFailure(errorMsg));
            });
    };
};


export const fetchConvaiCharacter = () => {
    return (dispatch) => {
        dispatch(fetchConvaiRequest());
        axiosInstance.get('/avatars/convai/')
            .then(response => {
                const avatar = response.data;
                dispatch(fetchConvaiSuccess(avatar));
            })
            .catch(error => {
                const errorMsg = error.message;
                dispatch(fetchConvaiFailure(errorMsg));
            });
    };
};

export const createConvaiCharacter = (updatedFormData, toast, history) => {
    console.log("🚀 ~ createConvaiCharacter ~ updatedFormData:", updatedFormData)
    return (dispatch) => {
        dispatch(createConvaiRequest());
        axiosInstance.post('/avatars/convai/', updatedFormData)
            .then(response => {
                const avatar = response.data;
                console.log("🚀 ~ return ~ avatar:", avatar?.character_id)
                dispatch(createConvaiSuccess(avatar));
                dispatch(fetchConvaiCharacter());
                toast({
                    title: `Conv AI character created with ID: ${avatar.character_id}`,
                    status: "success",
                    isClosable: true,
                    position: 'top',
                  });
                  history.push('/admin/company-info-section');
            })
            .catch(error => {
                const errorMsg = error.message;
                dispatch(createConvaiFailure(errorMsg));
                toast({
                    title: 'Error creating avatar',
                    status: "error",
                    isClosable: true,
                    position: 'top',
                  });
            });
    };
};



export const updateConvaiCharacter = (updatedFormData, toast, history) => {
    console.log("🚀 ~ updateConvaiCharacter ~ updatedFormData:", updatedFormData)
    return (dispatch) => {
        dispatch(createConvaiRequest());
        axiosInstance.post('/avatars/convai/update_character/', updatedFormData)
            .then(response => {
                const avatar = response.data;
                console.log("🚀 ~ return ~ avatar:", avatar?.character_id)
                dispatch(createConvaiSuccess(avatar));
                dispatch(fetchConvaiCharacter());
                toast({
                    title: `Conv AI character updated!`,
                    status: "success",
                    isClosable: true,
                    position: 'top',
                  });
                history.push('/admin/company-info-section');
            })
            .catch(error => {
                const errorMsg = error.response.data.Error;
                dispatch(createConvaiFailure(errorMsg));
                toast({
                    title: `${errorMsg}`,
                    status: "error",
                    isClosable: true,
                    position: 'top',
                  });
            });
    };
};


export const fetchAccent = (selectedLanguage) => {
    return (dispatch) => {
        dispatch(fetchAccentRequest());
        return axiosInstance.get(`/avatars/convai/list_accents/?language_code=${selectedLanguage}`)
           .then(response => {
                const accent = response.data;
                dispatch(fetchAccentSuccess(accent));
                return accent;
            })
           .catch(error => {
                const errorMsg = error.message;
                dispatch(fetchAccentFailure(errorMsg));
                throw error;
            });
    };
}