// reducers/userReducer.js
const initialState = {
    loading: false,
    users: [],
    singleUser: [],
    error: ''
  };
  
  const userReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_USERS_REQUEST':
        return {
          ...state,
          loading: true
        };
      case 'FETCH_USERS_SUCCESS':
        return {
          loading: false,
          users: action.payload,
          error: ''
        };
      case 'FETCH_USERS_FAILURE':
        return {
          loading: false,
          users: [],
          error: action.payload
        };
        case 'FETCH_SINGLE_USER_REQUEST':
        return {
          ...state,
          loading: true
        };
      case 'FETCH_SINGLE_USER_SUCCESS':
        return {
          loading: false,
          singleUser: action.payload,
          error: ''
        };
      case 'FETCH_SINGLE_USER_FAILURE':
        return {
          loading: false,
          singleUser: [],
          error: action.payload
        };
      default:
        return state;
    }
  };
  
  export default userReducer;
  