import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Flex, FormControl, FormLabel, Textarea, Input, VStack, Heading, Select, MenuItem, FormErrorMessage } from "@chakra-ui/react";
import { useDispatch, useSelector } from 'react-redux';
import { fetchAccent, fetchConvaiCharacter } from "actions/avatarActions";
import { LanguageContext } from 'context/LanguageContext';

const StepOne = ({ formData, setFormData, nextStep, submitForm }) => {
    const dispatch = useDispatch();
    const { translate, language } = useContext(LanguageContext);
    const accentData = useSelector(state => state.avatar?.list_accents);
    const convAI = useSelector(state => state?.avatar?.characterDetail);
    const [selectedLanguage, setSelectedLanguage] = useState(convAI[0]?.character_information?.language_code || 'ar');
    const isLoading = useSelector(state => state?.avatar?.loading);
    const [errors, setErrors] = useState({});
    const [multiLangSelected, setMultiLangSelected] = useState(false);
    const [englishAccent, setEnglishAccent] = useState(null);
    const [arabicAccent, setArabicAccent] = useState(null);

    useEffect(() => {
        if (convAI[0]?.character_information?.language_codes?.length > 1) {
            setMultiLangSelected(true);
        }
    }, [convAI]);

    useEffect(async () => {
        if(multiLangSelected && selectedLanguage === 'en-ar'){
            try{
                const response1 = await dispatch(fetchAccent('en-US'));
                setEnglishAccent(response1);
                const response2 = await dispatch(fetchAccent('ar'));
                setArabicAccent(response2);
            }
            catch(error){
                console.error('Failed to fetch user profile:', error);
            }
        }
        
    }, [multiLangSelected]);

    useEffect(async () => {
        console.log("selectedLanguage --->>> ",selectedLanguage);
        if(selectedLanguage === "en-ar"){
            setMultiLangSelected(true);
            return;
        }
        try{
            const response = await dispatch(fetchAccent(selectedLanguage));
            console.log("🚀 ~ useEffect ~ response:", response)
        }
        catch(error){
            console.error('Failed to fetch accent or character:', error);
        }
    }, [selectedLanguage]);

    const validateForm = () => {
        let tempErrors = {};
        if (!(formData.character_name || convAI[0]?.character_information?.character_name)) tempErrors.character_name = "Avatar Name is required";
        if (!(formData.character_ar_name || convAI[0]?.character_ar_name)) tempErrors.character_ar_name = "اسم الآلهة is required";
        if (!(formData.character_backstory || convAI[0]?.character_information?.backstory)) tempErrors.character_backstory = "Avatar's Backstory is required";
        if (!(formData.character_ar_backstory || convAI[0]?.character_ar_backstory)) tempErrors.character_ar_backstory = "قصة الصورة الرمزية is required";
        if (!(formData.character_language_code || convAI[0]?.character_information?.language_code[0])) tempErrors.character_language_code = "Language is required";
        if (!(formData.character_voice || convAI[0]?.character_information?.voice_type)) tempErrors.character_voice = "Avatar Voice is required";
        if (!formData.catch_phrase) tempErrors.catch_phrase = "Avatar Catch Phrase is required";
        if (!formData.catch_phrase_ar) tempErrors.catch_phrase_ar = "مطلوب عبارة التقاط الصورة الرمزية";

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleNextStep = () => {
        console.log("Next Step", formData)
        if (validateForm()) {
            nextStep();
        }
    };

    const handleUpdateCharacter = () => {
        setFormData({ ...formData, 
            avatar_model_type: convAI[0]?.character_information?.model_details?.modelType, 
            avatar_placeholder: convAI[0]?.character_information?.model_details?.modelPlaceholder, 
            avatar_url: convAI[0]?.character_information?.model_details?.modelLink
        })
        submitForm();
    };

    return (
        <VStack spacing={4}>
            <Heading as="h2" size="lg" textAlign="center" mb={6} className={language === 'ar' && "arabic_font"}>
                {translate("avatar-description")}
            </Heading>
            <FormControl isInvalid={errors.character_name}>
                <FormLabel>Avatar Name</FormLabel>
                <Input
                    defaultValue={formData.character_name || convAI[0]?.character_information?.character_name || ''}
                    onChange={(e) => setFormData({ ...formData, character_name: e.target.value })}
                />
                <FormErrorMessage>{errors.character_name}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.character_ar_name}>
                <FormLabel textAlign={"right"} className="arabic_font">اسم الآلهة</FormLabel>
                <Input
                    textAlign={"right"}
                    defaultValue={formData.character_ar_name || convAI[0]?.character_ar_name || ''}
                    onChange={(e) => setFormData({ ...formData, character_ar_name: e.target.value })}
                />
                <FormErrorMessage>{errors.character_ar_name}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.character_backstory}>
                <FormLabel>Who is Avatar? Avatar's Backstory!</FormLabel>
                {/* <Input
                    value={formData.character_backstory || convAI[0]?.character_information?.backstory || ''}
                    onChange={(e) => setFormData({ ...formData, character_backstory: e.target.value })}
                /> */}
                <Textarea defaultValue={formData.character_backstory || convAI[0]?.character_information?.backstory || ''}
                    onChange={(e) => setFormData({ ...formData, character_backstory: e.target.value })}
                />

                <FormErrorMessage>{errors.character_backstory}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.character_ar_backstory}>
                <FormLabel textAlign={"right"} className="arabic_font">من هو الرمزية؟ قصة الصورة الرمزية!</FormLabel>
                {/* <Input
                    textAlign={"right"}
                    value={formData.character_ar_backstory || convAI[0]?.character_ar_backstory || ''}
                    onChange={(e) => setFormData({ ...formData, character_ar_backstory: e.target.value })}
                /> */}
                <Textarea
                    textAlign={"right"}
                    defaultValue={formData.character_ar_backstory || convAI[0]?.character_ar_backstory || ''}
                    onChange={(e) => setFormData({ ...formData, character_ar_backstory: e.target.value })}
                />
                <FormErrorMessage>{errors.character_ar_backstory}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.character_language_code}>
                <FormLabel>Select Language</FormLabel>
                <Select
                disabled={isLoading}
                    value={ multiLangSelected ? 'en-ar' : formData.character_language_code || convAI[0]?.character_information?.language_code || ''}
                    onChange={(e) => {
                        setFormData({ ...formData, character_language_code: e.target.value });
                        setSelectedLanguage(e.target.value);
                        e.target.value !== 'en-ar' && setMultiLangSelected(false);
                    }}
                >
                    <option value="" disabled>Select Language</option>
                    <option value="en-US">English</option>
                    <option value="ar">Arabic</option>
                    <option value="en-ar">English and Arabic</option>
                </Select>
                <FormErrorMessage>{errors.character_language_code}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.character_voice}>
                <FormLabel>{`Avatar ${multiLangSelected && selectedLanguage === 'en-ar' ? 'English' : ''} Voice`}</FormLabel>
                <Select
                    disabled={isLoading}
                    value={formData.character_voice  || convAI[0]?.character_voice || ''}
                    onChange={(e) => {

                        setFormData({ ...formData, character_voice: e.target.value });
                    }}
                >
                    <option value="" disabled>Select Avatar Voice</option>
                    {!multiLangSelected && accentData && Object.keys(accentData).map((key) => (
                        <option key={key} value={accentData[key]}>
                            {key}
                        </option>
                    ))}
                    {multiLangSelected && englishAccent && Object.keys(englishAccent).map((key) => (
                        <option key={key} value={englishAccent[key]}>
                            {key}
                        </option>
                    ))}
                </Select>
                <FormErrorMessage>{errors.character_voice}</FormErrorMessage>
            </FormControl>
            {multiLangSelected && <FormControl isInvalid={errors.character_voice}>
                <FormLabel>Avatar Arabic Voice</FormLabel>
                <Select
                    disabled={isLoading}
                    value={formData.character_voice_ar || convAI[0]?.character_voice_ar || ''}
                    onChange={(e) => {
                        setFormData({ ...formData, character_voice_ar: e.target.value });
                    }}
                >
                    <option value="" disabled>Select Avatar Voice</option>
                    {arabicAccent && Object.keys(arabicAccent).map((key) => (
                        <option key={key} value={arabicAccent[key]}>
                            {key}
                        </option>
                    ))}
                </Select>
                <FormErrorMessage>{errors.character_voice}</FormErrorMessage>
            </FormControl>}
            <FormControl isInvalid={errors.catch_phrase}>
                <FormLabel>Avatar Catch Phrase</FormLabel>
                <Input
                    defaultValue={formData.catch_phrase}
                    onChange={(e) => setFormData({ ...formData, catch_phrase: e.target.value })}
                />
                <FormErrorMessage>{errors.catch_phrase}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.catch_phrase_ar}>
                <FormLabel textAlign={"right"} className="arabic_font">الصورة الرمزية قبض العبارة</FormLabel>
                <Input
                    textAlign={"right"}
                    defaultValue={formData.catch_phrase_ar}
                    onChange={(e) => setFormData({ ...formData, catch_phrase_ar: e.target.value })}
                />
                <FormErrorMessage textAlign={"right"}>{errors.catch_phrase_ar}</FormErrorMessage>
            </FormControl>
            <Flex>
                <Button onClick={handleNextStep}
                    bg="#F69320"
                    color="white"
                    _hover={{ bg: "#F67332" }}
                    className={language === 'ar' && "arabic_font"}
                >{translate("next")}</Button>
                {convAI?.length > 0 && <Button onClick={handleUpdateCharacter}
                    disabled={isLoading}
                    ml={4}
                    bg="#EF4959"
                    color="white"
                    _hover={{ bg: "#E43D50" }}
                    className={language === 'ar' && "arabic_font"}
                >{translate("update-character")}</Button>}
            </Flex>
        </VStack>
    );
};

export default StepOne;
