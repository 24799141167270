/* eslint-disable */
import React, { useEffect, useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
// chakra imports
import { Box, Flex, HStack, Text, useColorModeValue, useToast } from "@chakra-ui/react";
import { fetchConvaiCharacter } from 'actions/avatarActions';
import { useDispatch, useSelector } from "react-redux";
import { LanguageContext } from "context/LanguageContext";

export function SidebarLinks(props) {
  const dispatch = useDispatch();
  const toast = useToast();
  const { translate, language } = useContext(LanguageContext);
  const convAI = useSelector(state => state?.avatar?.characterDetail);
  const currentUser = useSelector(state => state?.currentUser?.currentUser);
  //   Chakra color mode
  let location = useLocation();
  let activeColor = useColorModeValue("gray.700", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let activeIcon = useColorModeValue("brand.500", "white");
  let textColor = useColorModeValue("secondaryGray.500", "white");
  let brandColor = useColorModeValue("brand.500", "brand.400");

  const { routes } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  useEffect(() => {
    dispatch(fetchConvaiCharacter());
  },[dispatch]);

  const handleNavLinkClick = (e, route) => {
    if((convAI?.length === 0) && (route.name === translate("route-show-my-avatar") || route.name === translate("route-company-info-section") || route.name === translate("route-knowledge-bank") || route.name === translate("route-narrative-structure"))){
      toast({
        title: `This route is disabled until you create your avatar.`,
        status: "info",
        isClosable: true,
        position: 'top',
    })
      e.preventDefault();
    }
  }

  const filterRoutesByRole = (routes, role) => {
    return routes.filter(route => {
      if (role === 'Admin') {
        return route.name === translate("route-company") || route.name === translate("route-company-profile-section") || route.name === translate("route-upload-tutorial-video");
      }
      if (role === 'Company') {
        return route.name === translate("route-profile") || route.name === translate("route-avatar-description") || route.name === translate("route-show-my-avatar") || route.name === translate("route-company-info-section") || route.name === translate("route-tutorial-video") || route.name === translate("route-knowledge-bank") || route.name === translate("route-narrative-structure");
      }
      return true; // default case, maybe for guest or other roles
    });
  };
  

  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (routes) => {
    const role = localStorage.getItem('role');
    const filteredRoutes = filterRoutesByRole(routes, role);
    return filteredRoutes.map((route, index) => {
      if (route.category) {
        return (
          <>
            <Text
              fontSize={"md"}
              color={activeColor}
              fontWeight='bold'
              mx='auto'
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              pt='18px'
              pb='12px'
              key={index}
              className={language === 'ar' && "arabic_font"}
              >
              {route.name}
            </Text>
            {createLinks(route.items)}
          </>
        );
      } else if (
        route.layout === "/admin" ||
        route.layout === "/auth"
      ) {
        return (
          <NavLink key={index} to={route.layout + route.path} onClick={(e) => handleNavLinkClick(e, route)} >
            {route.icon ? (
              <Box>
                <HStack
                  spacing={
                    activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                  }
                  py='5px'
                  ps='10px'>
                  <Flex w='100%' alignItems='center' justifyContent='center'>
                    <Box
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeIcon
                          : textColor
                      }
                      me='18px'>
                      {route.icon}
                    </Box>
                    <Text
                      me='auto'
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeColor
                          : textColor
                      }
                      fontWeight={
                        activeRoute(route.path.toLowerCase())
                          ? "bold"
                          : "normal"
                      }
                      className={language === 'ar' && "arabic_font"}
                      >
                      {route.name}
                    </Text>
                  </Flex>
                  <Box
                    h='36px'
                    w='4px'
                    bg={
                      activeRoute(route.path.toLowerCase())
                        ? brandColor
                        : "transparent"
                    }
                    borderRadius='5px'
                  />
                </HStack>
              </Box>
            ) : (
              <Box>
                <HStack
                  spacing={
                    activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                  }
                  py='5px'
                  ps='10px'>
                  <Text
                    me='auto'
                    color={
                      activeRoute(route.path.toLowerCase())
                        ? activeColor
                        : inactiveColor
                    }
                    fontWeight={
                      activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
                    }
                    className={language === 'ar' && "arabic_font"}
                    >
                    {route.name}
                  </Text>
                  <Box h='36px' w='4px' bg='brand.400' borderRadius='5px' />
                </HStack>
              </Box>
            )}
          </NavLink>
        );
      }
    });
  };
  //  BRAND
  return createLinks(routes);
}

export default SidebarLinks;
