import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Flex,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Select,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createCompanyAccount,
  fetchSingleCompanyAccount,
  updateMyAccountInfo,
  deleteSingleCompanyAccount,
} from 'actions/accountAction';
import { useProfile } from 'context/CompanyContext';
import ConfirmationModal from 'components/confirmation/ConfirmationModal'; 
import { LanguageContext } from 'context/LanguageContext';

const ManageAccounts = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { profile } = useProfile();
  const { translate, language } = useContext(LanguageContext);
  const [newAccount, setNewAccount] = useState({
    email: '',
    account_manager: '',
    password: '',
    contact_number: '',
    convai_key: '',
  });
  const [isFormVisible, setFormVisible] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [editAccountId, setEditAccountId] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false); // New state for delete modal
  const [accountToDelete, setAccountToDelete] = useState(null); // State to track the account to delete
  const accountsData = useSelector((state) => state.companyAccount.singleCompanyAccount);
  const allCompanies = useSelector((state) => state.user.users);

  useEffect(() => {
    if (profile) {
      dispatch(fetchSingleCompanyAccount(profile?.id));
    }
  }, [profile]);

  const handleAddAccount = async () => {
    try {
      if (isEditMode) {
        try{
          const response = await dispatch(updateMyAccountInfo(editAccountId, { ...newAccount, company_profile: profile?.id }));
          toast({
            title: 'Success!',
            description: 'Account updated successfully.',
            status:'success',
            duration: 5000,
            isClosable: true,
            position: 'top',
          })
        }
        catch(err){
          console.log("🚀 ~ handleAddAccount ~ err:", err);
          toast({
            title: 'Error',
            description: 'Failed to update account.',
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top',
          })
        }
      } else {
        try{
          await dispatch(createCompanyAccount({ ...newAccount, company_profile: profile?.id }));
          toast({
            title: 'Success!',
            description: 'Account created successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true,
position: 'top',
          });
        }
        catch(err){
        console.log("🚀 ~ handleAddAccount ~ err:", err?.response?.data?.email[0] || '');
        toast({
          title: 'Error',
          description: err?.response?.data?.email[0] || 'Failed to create account.',
          status: 'error',
          duration: 5000,
          isClosable: true,
position: 'top',
        });
        }
      }
      await dispatch(fetchSingleCompanyAccount(profile?.id));
    } catch (error) {
      console.error('Failed to save company account:', error);
    }
    setNewAccount({
      email: '',
      account_manager: '',
      password: '',
      contact_number: '',
      convai_key: '',
    });
    setFormVisible(false);
    setEditMode(false);
    setEditAccountId(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAccount({ ...newAccount, [name]: value });
  };

  const toggleFormVisibility = () => {
    setFormVisible(!isFormVisible);
  };

  const handleEditClick = (account) => {
    setNewAccount(account);
    setEditMode(true);
    setEditAccountId(account.id);
    setFormVisible(true);
  };

  const handleDeleteClick = (accountId) => {
    setAccountToDelete(accountId); // Set the account to delete
    setDeleteModalOpen(true); // Open the delete confirmation modal
  };

  const confirmDelete = async () => {
    try {
      const response = await dispatch(deleteSingleCompanyAccount(accountToDelete));
      await dispatch(fetchSingleCompanyAccount(profile?.id));
      toast({
        title: 'Success!',
        description: 'Account deleted successfully.',
        status:'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    } catch (error) {
      console.error('Failed to delete company account:', error);
      toast({
        title: 'Error',
        description: 'Failed to delete account.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      })
    }
    setDeleteModalOpen(false); // Close the delete confirmation modal
    setAccountToDelete(null); // Reset the account to delete
  };

  const handleCloseModal = () => {
    setFormVisible(false);
    setEditMode(false);
    setEditAccountId(null);
    setNewAccount({
      email: '',
      account_manager: '',
      password: '',
      contact_number: '',
      convai_key: '',
    });
  };

  return (
    <Box>
      {!isFormVisible && (
        <Flex justifyContent="flex-end" mb={4}>
          <Button 
          bg="#F69320" 
          color="white" 
          _hover={{ bg: "#F67332"}}
           size="sm" onClick={toggleFormVisibility}
           className={language === 'ar' && "arabic_font"}
           >
            {isFormVisible ? 'Hide Account Form' : translate("add-account")}
          </Button>
        </Flex>
      )}
      <Table variant="simple" mt={4}>
        <Thead>
          <Tr>
            <Th className={language === 'ar' && "arabic_font"}>{translate("email")}</Th>
            <Th className={language === 'ar' && "arabic_font"}>{translate("account-manager")}</Th>
            <Th className={language === 'ar' && "arabic_font"}>{translate("contact-number")}</Th>
            <Th className={language === 'ar' && "arabic_font"}>{translate("convai-key")}</Th>
            <Th className={language === 'ar' && "arabic_font"}>{translate("column-header-action")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {accountsData &&
            accountsData.map((account, index) => (
              <Tr key={index}>
                <Td>{account.email}</Td>
                <Td>{account.account_manager}</Td>
                <Td>{account.contact_number}</Td>
                <Td>{account.convai_key}</Td>
                <Td>
  <Flex alignItems="center">
    <Button 
    mr={2}
      bg="#F69320" 
      color="white" 
      _hover={{ bg: "#F67332" }}
      size="xs" 
      onClick={() => handleEditClick(account)}
      className={language === 'ar' ? "arabic_font" : ""}
    >
      {translate("edit")}
    </Button>
    <Button 
      colorScheme="red" 
      size="xs" 
      onClick={() => handleDeleteClick(account.id)}
      className={language === 'ar' ? "arabic_font" : ""}
    >
      {translate("btn-delete")}
    </Button>
  </Flex>
</Td>
              </Tr>
            ))}
        </Tbody>
      </Table>

      {isFormVisible && (
        <Modal isOpen={isFormVisible} onClose={handleCloseModal} size="md">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader style={language === 'ar' ? { textAlign: 'right', marginRight: '25px' } : {}} 
            className={language === 'ar' && "arabic_font"}>{isEditMode ? translate("update-account") : translate("add-account")}</ModalHeader>
            <ModalCloseButton />
            <ModalBody mb={8}>
              <VStack spacing={4} align="stretch">
                <FormControl id="email" isRequired>
                  <FormLabel 
                  style={language === 'ar' ? { textAlign: 'right' } : {}} 
                  dir={language === 'ar' ? 'rtl' : 'ltr'}
                  className={language === 'ar' && "arabic_font"}>{translate("email")}</FormLabel>
                  <Input
                  dir={language === 'ar' ? 'rtl' : 'ltr'}
                  style={language === 'ar' ? { textAlign: 'right' } : {}} 
                  readOnly={isEditMode}
                    name="email"
                    value={newAccount.email}
                    onChange={handleInputChange}
                    className={language === 'ar' && "arabic_font"}
                    placeholder={translate("email")}
                  />
                </FormControl>

                <FormControl id="account_manager" isRequired>
                  <FormLabel 
                  dir={language === 'ar' ? 'rtl' : 'ltr'}
                  style={language === 'ar' ? { textAlign: 'right' } : {}} 
                  className={language === 'ar' && "arabic_font"}>{translate("account-manager")}</FormLabel>
                  <Input
                  dir={language === 'ar' ? 'rtl' : 'ltr'}
                  style={language === 'ar' ? { textAlign: 'right' } : {}} 
                    name="account_manager"
                    value={newAccount.account_manager}
                    onChange={handleInputChange}
                    className={language === 'ar' && "arabic_font"}
                    placeholder={translate("account-manager")}
                  />
                </FormControl>

                {!isEditMode && (
                  <FormControl id="password" isRequired>
                    <FormLabel 
                    dir={language === 'ar' ? 'rtl' : 'ltr'}
                    style={language === 'ar' ? { textAlign: 'right' } : {}} 
                    className={language === 'ar' && "arabic_font"}>{translate("password")}</FormLabel>
                    <Input
                    dir={language === 'ar' ? 'rtl' : 'ltr'}s
                    style={language === 'ar' ? { textAlign: 'right' } : {}} 
                      name="password"
                      type="password"
                      value={newAccount.password}
                      onChange={handleInputChange}
                      className={language === 'ar' && "arabic_font"}
                      placeholder={translate("password")}
                    />
                  </FormControl>
                )}

                <FormControl id="contact_number" isRequired>
                  <FormLabel 
                  dir={language === 'ar' ? 'rtl' : 'ltr'}
                  style={language === 'ar' ? { textAlign: 'right' } : {}} 
                  className={language === 'ar' && "arabic_font"}>{translate("contact-number")}</FormLabel>
                  <Input
                  dir={language === 'ar' ? 'rtl' : 'ltr'}
                  style={language === 'ar' ? { textAlign: 'right' } : {}} 
                    name="contact_number"
                    value={newAccount.contact_number}
                    onChange={handleInputChange}
                    className={language === 'ar' && "arabic_font"}
                    placeholder={translate("contact-number")}
                  />
                </FormControl>

                <FormControl id="convai_key" isRequired>
                  <FormLabel 
                  dir={language === 'ar' ? 'rtl' : 'ltr'}
                  style={language === 'ar' ? { textAlign: 'right' } : {}} 
                  className={language === 'ar' && "arabic_font"}>{translate("convai-key")}</FormLabel>
                  <Input
                  dir={language === 'ar' ? 'rtl' : 'ltr'}
                  style={language === 'ar' ? { textAlign: 'right' } : {}} 
                    name="convai_key"
                    value={newAccount.convai_key}
                    onChange={handleInputChange}
                    className={language === 'ar' && "arabic_font"}
                    placeholder={translate("convai-key")}
                  />
                </FormControl>

                <Button 
                disabled={!newAccount.email || !newAccount.convai_key || (!isEditMode && !newAccount.password)}
                bg="#F69320" 
                color="white" 
                _hover={{ bg: "#F67332"}}
                onClick={handleAddAccount}
                className={language === 'ar' && "arabic_font"}
                >
                  {isEditMode ? translate("update-account") : translate("add-account")}
                </Button>
              </VStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {/* Delete Confirmation Modal */}
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={confirmDelete}
        title={translate("delete-account")}
        message={translate("delete-account-text")}
      />
    </Box>
  );
};

export default ManageAccounts;
