import React, { useEffect, useRef, useCallback, useState, useContext } from 'react';
import { Box, Button, Flex, FormControl, FormLabel, Input, VStack, Heading, CardBody, CardHeader, Stack, StackDivider, Center, useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from 'react-redux';
import { createAvatar } from 'actions/avatarActions';
import Card from 'components/card/Card';
import { LanguageContext } from 'context/LanguageContext';

const StepTwo = ({ formData, setFormData, nextStep, prevStep }) => {
    const iframeRef = useRef(null);
    const subdomain = 'test-application-buv76w'; // Replace with your custom subdomain
    const dispatch = useDispatch();
    const toast = useToast();
    const convAI = useSelector(state => state?.avatar?.characterDetail);
    const { translate, language } = useContext(LanguageContext);  

    const [avatarDetails, setAvatarDetails] = useState({});

    useEffect(() => {
        Object.keys(avatarDetails).length > 0 && dispatch(createAvatar(avatarDetails));
    }, [avatarDetails]);

    useEffect(() => {
        const frame = iframeRef.current;
        frame.src = `https://${subdomain}.readyplayer.me/avatar?frameApi`;

        const subscribe = (event) => {
            const json = parse(event);

            if (json?.source !== 'readyplayerme') {
                return;
            }

            if (json.eventName === 'v1.frame.ready') {
                frame.contentWindow.postMessage(
                    JSON.stringify({
                        target: 'readyplayerme',
                        type: 'subscribe',
                        eventName: 'v1.**',
                    }),
                    '*'
                );
            }

            if (json.eventName === 'v1.avatar.exported') {
                console.log(`Avatar URL: ${json.data.url}`);
                console.log(`Avatar URL: ${json.data.avatarId}`);
                const url = json.data.url;
                const id = json.data.avatarId;
                setFormData({
                    ...formData,
                    avatar_url: url,
                    avatarId: id,
                    avatar_placeholder: `https://models.readyplayer.me/${id}.png?pose=relaxed&background=0,0,60&size=512`,
                    avatar_model_type: 'RPM-3D',
                })
                const avatar_url = `${json.data.url}`;
                const avatar_id = `${json.data.avatarId}`;
                // document.getElementById('avatarUrl').innerHTML = `Avatar URL: ${json.data.url}`;
                setAvatarDetails({
                    avatar_url,
                    avatar_id,
                })
                // frame.hidden = true;
            }

            if (json.eventName === 'v1.user.set') {
                console.log(`User with id ${json.data.id} set: ${JSON.stringify(json)}`);
            }
        };

        window.addEventListener('message', subscribe);
        document.addEventListener('message', subscribe);

        return () => {
            window.removeEventListener('message', subscribe);
            document.removeEventListener('message', subscribe);
        };
    }, [subdomain]);

    const parse = (event) => {
        try {
            return JSON.parse(event.data);
        } catch (error) {
            return null;
        }
    };

    const handleNextStep = () => {
        if (Object.keys(avatarDetails).length === 0) {
            toast({
                title: 'Error',
                description: 'Missing Avatar details. Please complete the process to configure Avatar first.',
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
        } else {
          nextStep();
        }
      };

    return (
        <VStack spacing={4}>
            <Box width="100%" mx="auto" mt={20} p={6} borderWidth={1} borderRadius="lg" bg="white">
                <Box borderBottom={1} borderColor="black">
                    <Heading as="h2" size="lg" textAlign="center" mb={6} className={language === 'ar' && "arabic_font"}>
                    {`${convAI?.length > 0 ? translate("update") : translate("create")} ${translate("avatar")}`}
                    </Heading>
                </Box>
                <Box w="100%" h={500} >
                    <iframe
                        ref={iframeRef}
                        className="frame"
                        style={{ width: '100%', height: '100%', borderRadius: '10px' }}
                        allow="camera *; microphone *; clipboard-write"
                    ></iframe>
                </Box>
                <Flex justifyContent="center" mt="10">
                    <Button onClick={prevStep} mr={2} bg="gray.500" color="gray.100" _hover={{ bg: "gray.700" }} className={language === 'ar' && "arabic_font"}>{translate("back")}</Button>
                    {Object.keys(avatarDetails).length !== 0 && <Button onClick={handleNextStep} 
                    bg="#F69320" 
                    color="white" 
                    _hover={{ bg: "#F67332" }}
                    className={language === 'ar' && "arabic_font"}
                    >{translate("next")}</Button>}
                </Flex>
            </Box>
        </VStack>
    )
}

export default StepTwo;