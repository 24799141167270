// actions/userActions.js
// import axios from 'axios';
import axiosInstance from '../axiosConfig';

export const fetchUsersRequest = () => {
    return {
        type: 'FETCH_USERS_REQUEST'
    };
};

export const fetchUsersSuccess = (users) => {
    return {
        type: 'FETCH_USERS_SUCCESS',
        payload: users
    };
};

export const fetchUsersFailure = (error) => {
    return {
        type: 'FETCH_USERS_FAILURE',
        payload: error
    };
};

export const fetchSingleUserRequest = () => {
    return {
        type: 'FETCH_SINGLE_USER_REQUEST'
    };
};

export const fetchSingleUserSuccess = (singleUser) => {
    return {
        type: 'FETCH_SINGLE_USER_SUCCESS',
        payload: singleUser
    };
};

export const fetchSingleUserFailure = (error) => {
    return {
        type: 'FETCH_SINGLE_USER_FAILURE',
        payload: error
    };
};

export const createUserRequest = () => {
    return {
        type: 'CREATE_USER_REQUEST'
    };
};

export const createUserSuccess = (users) => {
    return {
        type: 'CREATE_USER_SUCCESS',
        payload: users
    };
};

export const createUserFailure = (error) => {
    return {
        type: 'CREATE_USER_FAILURE',
        payload: error
    };
};

export const updateUserRequest = () => {
    return {
        type: 'UPDATE_USER_REQUEST'
    };
};

export const updateUserSuccess = (user) => {
    return {
        type: 'UPDATE_USER_SUCCESS',
        payload: user
    };
};

export const updateUserFailure = (error) => {
    return {
        type: 'UPDATE_USER_FAILURE',
        payload: error
    };
};

export const fetchUsers = () => {
    return (dispatch) => {
        dispatch(fetchUsersRequest());
        axiosInstance.get('/companies/companies/')
            .then(response => {
                const users = response.data;
                dispatch(fetchUsersSuccess(users));
            })
            .catch(error => {
                const errorMsg = error.message;
                dispatch(fetchUsersFailure(errorMsg));
            });
    };
};

export const createUser = (company) => {
    return (dispatch) => {
        dispatch(createUserRequest());
        return axiosInstance.post('/companies/companies/', company)
            .then(response => {
                const users = response.data;
                dispatch(createUserSuccess(users));
                dispatch(fetchUsers());
                return users;
            })
            .catch(error => {
                const errorMsg = error.message;
                dispatch(createUserFailure(errorMsg));
                throw error;
            });
    };
};

export const updateUser = (id, updatedData, toast) => {
    return (dispatch) => {
        dispatch(updateUserRequest());
        axiosInstance.patch(`/companies/companies/${id}/`, updatedData)
            .then(response => {
                const user = response.data;
                toast({
                    title: `${user?.detail}`,
                    status: "success",
                    isClosable: true,
                    position: 'top',
                });
                dispatch(updateUserSuccess(user));
                dispatch(fetchUsers());
            })
            .catch(error => {
                const errorMsg = error.message;
                dispatch(updateUserFailure(errorMsg));
                toast({
                    title: "Error updating company.",
                    status: "error",
                    isClosable: true,
                    position: 'top',
                });
                dispatch(fetchUsers());
            });
    };
};

export const deleteUser = (id, toast) => {
    return (dispatch) => {
        axiosInstance.delete(`/companies/companies/${id}/`)
            .then(response => {
                toast({
                    title: "Company Deleted successfully.",
                    status: "success",
                    isClosable: true,
                    position: 'top',
                });
                dispatch(fetchUsers());
            })
            .catch(error => {
                const errorMsg = error.message;
                toast({
                    title: "Error deleting company.",
                    status: "error",
                    isClosable: true,
                    position: 'top',
                });
            });
    };
};

export const fetchUserById = (id) => {
    return (dispatch) => {
        dispatch(fetchSingleUserRequest());
        axiosInstance.get(`/companies/companies/${id}`)
            .then(response => {
                const singleUser = response.data;
                dispatch(fetchSingleUserSuccess(singleUser));
            })
            .catch(error => {
                const errorMsg = error.message;
                dispatch(fetchSingleUserFailure(errorMsg));
            });
    };
};