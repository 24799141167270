import React, { useState, useEffect, useContext } from 'react';
import { Box, Tabs, TabList, TabPanels, TabPanel, Tab, useToast } from '@chakra-ui/react';
import Banner from "views/admin/viewCompanyProfile/components/Banner";
import ManageCompanyInfo from './ManageCompanyInfo';
import ManageAccounts from './ManageAccounts';
import GeneralKnowledgeBase from './GeneralKnowledgeBase';
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/images.jpeg";
import Card from "components/card/Card.js";
import { useProfile } from 'context/CompanyContext';
import { useSelector, useDispatch } from "react-redux";
import { fetchUsers } from "actions/userActions";
import { updateCompanyProfile } from "actions/profileAction";
import { LanguageContext } from 'context/LanguageContext';

const ViewCompanyProfile = ({ }) => {
    const { profile, setProfile } = useProfile();
    const allCompanies = useSelector(state => state.user.users);
    const [companyDetails, setCompanyDetails] = useState(null);
    const companyId = profile?.company || '';
    const profileImage = profile?.profile_image || '';
    const dispatch = useDispatch();
    const toast = useToast();
    const { translate, language } = useContext(LanguageContext);  

    useEffect(() => {
        dispatch(fetchUsers());
    }, [profile]);

    useEffect(() => {
        const filteredCompany = allCompanies.find(company => company.id === companyId);
        setCompanyDetails(filteredCompany);
    }, [allCompanies]);


    const handleAvatarChange = async (event) => {
        const file = event.target.files[0];

        const updatedProfile = {
            ...profile,
            profile_image: file,
            // other profile fields as needed
        };

        try {
            // Dispatching the Redux action to update the company profile
            const response = await dispatch(updateCompanyProfile(updatedProfile));
            console.log('Profile updated successfully:', response);
            setProfile(response)
            toast({
                title: 'Updated',
                description: 'Profile Image updated.',
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: 'top',
            });
        } catch (error) {
            console.error('Error updating profile:', error);
            toast({
                title: 'Error',
                description: 'Error updating profile. Please try again.',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top',
            });
            // Show error toast message or other UI feedback
        }
    };



    return (
        <Box mt="80px">
            {/* Profile Banner */}
            <Banner
                gridArea="1 / 1 / 2 / 2"
                banner={banner}
                avatar={profileImage || avatar}
                name={companyDetails?.company_name || "Company Name"}
                // company_name={profile?.company_name || "Here is the company name"}
                industry={companyDetails?.website || ""}
                size={companyDetails?.size || ""}
                active={profile?.is_active === true ? "Yes" : "No" || ""}
                onAvatarChange={handleAvatarChange}
            />

            <Card
                w="100%"
                gridArea="1 / 1 / 2 / 2"
                mb={{ base: "0px", lg: "20px" }}
                align='center'
            >
                {/* Tabs for Sections */}
                <Tabs mt="20px">
                    <TabList>
                        <Tab className={language === 'ar' && "arabic_font"}>{translate("manage-comp-info")}</Tab>
                        <Tab className={language === 'ar' && "arabic_font"}>{translate("manage-accounts")}</Tab>
                        <Tab className={language === 'ar' && "arabic_font"}>{translate("manage-general-knowledge")}</Tab>
                    </TabList>

                    <TabPanels>
                        <TabPanel>
                            <ManageCompanyInfo />
                        </TabPanel>
                        <TabPanel>
                            <ManageAccounts />
                        </TabPanel>
                        <TabPanel>
                            <GeneralKnowledgeBase />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Card>
        </Box>
    );
};

export default ViewCompanyProfile;
