import axiosInstance from '../axiosConfig';

export const fetchProfilesRequest = () => {
    return {
        type: 'FETCH_PROFILES_REQUEST'
    };
};

export const fetchProfilesSuccess = (companyProfiles) => {
    return {
        type: 'FETCH_PROFILES_SUCCESS',
        payload: companyProfiles
    };
};

export const fetchProfilesFailure = (error) => {
    return {
        type: 'FETCH_PROFILES_FAILURE',
        payload: error
    };
};

export const fetchCompanyProfiles = () => {
    return (dispatch) => {
        dispatch(fetchProfilesRequest());
        axiosInstance.get('/companies/company_profiles/')
            .then(response => {
                const companyProfiles = response.data;
                dispatch(fetchProfilesSuccess(companyProfiles));
            })
            .catch(error => {
                const errorMsg = error.message;
                dispatch(fetchProfilesFailure(errorMsg));
            });
    };
};

export const createCompanyProfileRequest = () => {
    return {
        type: 'CREATE_ACCOUNT_REQUEST'
    };
};

export const createCompanyProfileSuccess = (companyProfile) => {
    return {
        type: 'CREATE_ACCOUNT_SUCCESS',
        payload: companyProfile
    };
};

export const createCompanyProfileFailure = (error) => {
    return {
        type: 'CREATE_ACCOUNT_FAILURE',
        payload: error
    };
};

export const createCompanyProfile = (companyProfile) => {
    console.log("🚀 ~ createCompanyProfile ~ companyProfile:", companyProfile)
    return (dispatch) => {
        dispatch(createCompanyProfileRequest());
        return axiosInstance.post('/companies/company_profiles/', companyProfile)
            .then(response => {
                const companyProfiles = response.data;
                dispatch(createCompanyProfileSuccess(companyProfiles));
                dispatch(fetchCompanyProfiles());
            })
            .catch(error => {
                const errorMsg = error.message;
                dispatch(createCompanyProfileFailure(errorMsg));
                throw error; // rethrow the error to be caught in the component
            });
    };
};


export const updateCompanyProfile = (companyProfile) => {
    return async (dispatch) => {
        dispatch({ type: 'UPDATE_COMPANY_PROFILE_REQUEST' });

        try {
            // Prepare FormData
            const formData = new FormData();
            formData.append('company', companyProfile.company);
            formData.append('industry', companyProfile.industry);
            formData.append('industry_ar', companyProfile.industry_ar);
            formData.append('activities', companyProfile.activities);
            formData.append('activities_ar', companyProfile.activities_ar);
            formData.append('foundation_details', companyProfile.foundation_details);
            formData.append('foundation_details_ar', companyProfile.foundation_details_ar);
            formData.append('founders', companyProfile.founders);
            formData.append('founders_ar', companyProfile.founders_ar);
            formData.append('company_mission', companyProfile.company_mission);
            formData.append('company_mission_ar', companyProfile.company_mission_ar);
            formData.append('company_revenue', companyProfile.company_revenue);
            formData.append('company_revenue_ar', companyProfile.company_revenue_ar);
            formData.append('company_certificates', companyProfile.company_certificates);
            formData.append('company_certificates_ar', companyProfile.company_certificates_ar);
            formData.append('company_projects', companyProfile.company_projects);
            formData.append('company_projects_ar', companyProfile.company_projects_ar);
            formData.append('company_collaboration', companyProfile.company_collaboration);
            formData.append('company_collaboration_ar', companyProfile.company_collaboration_ar);
            formData.append('company_commitments', companyProfile.company_commitments);
            formData.append('company_commitments_ar', companyProfile.company_commitments_ar);
            formData.append('company_challenges', companyProfile.company_challenges);
            formData.append('company_challenges_ar', companyProfile.company_challenges_ar);
            formData.append('extra_info', JSON.stringify(companyProfile.extra_info)); // Ensure object fields are stringified
            formData.append('is_active', companyProfile.is_active);

            // Handle profile_image as a file
            if (companyProfile.profile_image instanceof File || companyProfile.profile_image instanceof Blob) {
                formData.append('profile_image', companyProfile.profile_image);
            } else {
                console.warn('Profile image is not a file or blob; skipping file upload.');
            }

            // Handle banner as a file
            if (companyProfile.banner instanceof File || companyProfile.banner instanceof Blob) {
                formData.append('banner', companyProfile.banner);
            } else {
                console.warn('Banner is not a file or blob; skipping file upload.');
            }

            // Correctly reference the company profile ID in the URL
            const response = await axiosInstance.put(
                `/companies/company_profiles/${companyProfile.id}/`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Ensure the headers allow FormData
                    },
                }
            );

            const companyProfiles = response.data;

            dispatch({
                type: 'UPDATE_COMPANY_PROFILE_SUCCESS',
                payload: companyProfiles,
            });

            // Optionally refetch profiles if needed
            dispatch(fetchCompanyProfiles());
            dispatch(fetchProfileById(companyProfile.id));

            return companyProfiles;
        } catch (error) {
            const errorMsg = error.response?.data?.message || error.message;

            dispatch({
                type: 'UPDATE_COMPANY_PROFILE_FAILURE',
                payload: errorMsg,
            });

            throw error;
        }
    };
};


export const deleteCompanyProfile = (companyProfileId) => {
    return async (dispatch) => {
        dispatch({
            type: 'DELETE_COMPANY_PROFILE_REQUEST',
        });

        try {
            await axiosInstance.delete(`/companies/company_profiles/${companyProfileId}/`);
            
            dispatch({
                type: 'DELETE_COMPANY_PROFILE_SUCCESS',
                payload: companyProfileId,
            });
            
            dispatch(fetchCompanyProfiles());
        } catch (error) {
            const errorMsg = error.response?.data?.message || error.message; 

            dispatch({
                type: 'DELETE_COMPANY_PROFILE_FAILURE',
                payload: errorMsg,
            });

            throw error; 
        }
    };
};

export const fetchProfileById = (id) => {
    return (dispatch) => {
        dispatch({
            type: 'FETCH_SINGLE_PROFILE_REQUEST',
        });
        return axiosInstance.get(`companies/company_profiles/${id}`)
            .then(response => {
                const singleProfile = response.data;
                dispatch({
                    type: 'FETCH_SINGLE_PROFILE_SUCCESS',
                    payload: singleProfile,
                });
                return singleProfile;
            })
            .catch(error => {
                const errorMsg = error.message;
                dispatch({
                    type: 'FETCH_SINGLE_PROFILE_FAILURE',
                    payload: errorMsg,
                });
                throw error;
            });
    };
};
