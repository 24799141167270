import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Heading,
  Select,
  RadioGroup,
  Radio,
  HStack,
  IconButton
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";

const StepFour = ({ formData, setFormData, prevStep, nextStep }) => {
  const [rows, setRows] = useState([{ type: '', question: '', answer: '', speaker: '' }]);

  useEffect(() => {
    const concatenatedString = rows.map(item => `${item.question}: ${item.answer}`).join(', ');
    console.log(concatenatedString);
    setFormData({ ...formData, character_knowledge_bank: concatenatedString });
  }, [rows]);

  const addRow = () => {
    if (rows.length < 10) {
      setRows([...rows, { type: '', question: '', answer: '', speaker: '' }]);
    }
  };

  const handleInputChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const deleteRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
  };

  return (
    <VStack spacing={4}>
      <Heading as="h2" size="lg" textAlign="center" mb={6}>
        Knowledge Bank
      </Heading>

      <Button onClick={addRow} colorScheme="blue" mt={4} ml="auto">
        Add Row
      </Button>

      {rows.map((row, index) => (
        <Flex key={index} gap={6} alignItems="center">
          <FormControl>
            <FormLabel>Question Type</FormLabel>
            <Select
              value={row.type}
              onChange={(e) => handleInputChange(index, 'type', e.target.value)}
            >
              <option value="single">Single</option>
              <option value="tree">Tree/Horizontal</option>
            </Select>
          </FormControl>
          <FormControl >
            <FormLabel>Question</FormLabel>
            <Input
              value={row.question}
              onChange={(e) => handleInputChange(index, 'question', e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Answer</FormLabel>
            <Input
              value={row.answer}
              onChange={(e) => handleInputChange(index, 'answer', e.target.value)}
            />
          </FormControl>
          <FormControl as="fieldset">
            <FormLabel as="legend">Speaker</FormLabel>
            <RadioGroup
              value={row.speaker}
              onChange={(value) => handleInputChange(index, 'speaker', value)}
            >
              <HStack spacing={4}>
                <Radio value="human">Human</Radio>
                <Radio value="avatar">Avatar</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          {/* {index < rows.length - 1 && 
          ( */}
            <IconButton 
            size="sm"
              aria-label="Delete row"
              icon={<DeleteIcon />}
              onClick={() => deleteRow(index)}
              colorScheme="red"
            />
          {/* )} */}
        </Flex>
      ))}

      <Flex>
        {/* <Button onClick={prevStep} mr={2} bg="gray.500" color="gray.100" _hover={{ bg: "gray.700" }}>
          Back
        </Button> */}
        <Button onClick={nextStep} colorScheme="blue">
          Next
        </Button>
      </Flex>
    </VStack>
  );
};

export default StepFour;
