import React, { useContext } from "react";
import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdLock,
  MdOutlineVideoFile,
  MdInfoOutline,
  MdImage,
  MdOutlinePerson,
  MdOutlineAddchart,
  MdAccountTree,
} from "react-icons/md";
import { LanguageContext } from 'context/LanguageContext';

// Admin Imports
import Profile from "views/admin/profile";
import MultiStepForm from "views/admin/multiStepForm";
import DataTables from "views/admin/dataTables";
import Company from "views/admin/company";
import ShowAvatar from "views/admin/avatar";
import CompanyInfoSection from "views/admin/companyInfoSection";
import TutorialVideo from "views/admin/tutorial";
import KnowledgeBank from "views/admin/knowledgeBank";
import CompanyProfileSection from "views/admin/companyProfileSection";
import ViewCompanyProfile from "views/admin/viewCompanyProfile";
import TextEditorComponent from "views/admin/tutorialAdmin";
import TreeFlow from "views/admin/treeStructure";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import ResetPassword from "views/auth/resetPassword";

const RoutesComponent = () => {
  const { translate } = useContext(LanguageContext);

  const routes = [
    {
      name: translate("route-data-tables"),
      layout: "/admin",
      icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
      path: "/data-tables",
      component: DataTables,
    },
    {
      name: translate("route-profile"),
      layout: "/admin",
      path: "/profile",
      icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
      component: Profile,
    },
    {
      name: translate("route-avatar-description"),
      layout: "/admin",
      path: "/create-convai-character",
      icon: <Icon as={MdOutlinePerson} width='20px' height='20px' color='inherit' />,
      component: MultiStepForm,
    },
    {
      name: translate("route-company-info-section"),
      layout: "/admin",
      icon: <Icon as={MdInfoOutline} width='20px' height='20px' color='inherit' />,
      path: "/company-info-section",
      component: CompanyInfoSection,
    },
    {
      name: translate("route-knowledge-bank"),
      layout: "/admin",
      icon: <Icon as={MdOutlineAddchart} width='20px' height='20px' color='inherit' />,
      path: "/knowledge-bank",
      component: KnowledgeBank,
    },
    {
      name: translate("route-narrative-structure"),
      layout: "/admin",
      icon: <Icon as={MdAccountTree} width='20px' height='20px' color='inherit' />,
      path: "/narrative-structure",
      component: TreeFlow,
    },
    {
      name: translate("route-company"),
      layout: "/admin",
      icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
      path: "/company",
      component: Company,
    },
    {
      name: translate("route-company-profile-section"),
      layout: "/admin",
      icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
      path: "/profile-section",
      component: CompanyProfileSection,
    },
    {
      // name: translate("route-view-company-profile"),
      layout: "/admin",
      icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
      path: "/view-profile-section",
      component: ViewCompanyProfile,
    },
    {
      name: translate("route-show-my-avatar"),
      layout: "/admin",
      icon: <Icon as={MdImage} width='20px' height='20px' color='inherit' />,
      path: "/show-my-avatar",
      component: ShowAvatar,
    },
    {
      name: translate("route-tutorial-video"),
      layout: "/admin",
      icon: <Icon as={MdOutlineVideoFile} width='20px' height='20px' color='inherit' />,
      path: "/tutorial-video",
      component: TutorialVideo,
    },
    {
      name: translate("route-upload-tutorial-video"),
      layout: "/admin",
      icon: <Icon as={MdOutlineVideoFile} width='20px' height='20px' color='inherit' />,
      path: "/upload-tutorial-video",
      component: TextEditorComponent,
    },
    {
      name: translate("route-sign-in"),
      layout: "/auth",
      path: "/sign-in",
      icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
      component: SignInCentered,
    },
    {
      name: translate("route-reset-password"),
      layout: "/auth",
      path: "/forgot-password",
      icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
      component: ResetPassword,
    },
  ];

  return routes;
};

export default RoutesComponent;
