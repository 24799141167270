import React from "react";
import { Flex, Box, Image, useColorModeValue } from "@chakra-ui/react";
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  // Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align="center" direction="column">
      <Box
        height="auto"
        margin="0"
        padding="0"
        display="flex"
        alignItems="center"
      >
        <Image
          src="logo.png"
          alt="Sidebar Brand"
          h="100px"
          w="auto"
          _hover={{ transform: "scale(1.05)", transition: "all 0.3s", cursor: "pointer" }} 
          margin="0"
          padding="0"
        />
      </Box>
      <HSeparator mt="14px" mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
