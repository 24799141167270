import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Heading,
  useToast,
  Icon,
  Grid
} from '@chakra-ui/react';
import { fetchMyAccountInfo, updateMyAccountInfo } from "actions/accountAction";
import { useDispatch, useSelector } from 'react-redux';
import { FaBuilding, FaPhone, FaIndustry, FaUsers, FaLock } from 'react-icons/fa';
import Banner from "views/admin/profile/components/Banner";
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/images.jpeg";
import Card from "components/card/Card.js";
import { LanguageContext } from 'context/LanguageContext';

const Overview = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { translate, language } = useContext(LanguageContext);
  const [formData, setFormData] = useState({
    email: '',
    contact_number: '',
    account_manager: '',
    convai_key: '',
    profile_image: '',
  });
  const [singleUser, setSingleUser] = useState();
  const user = useSelector((state) => state.companyAccount?.myAccountInfo);
  const currentUser = useSelector((state) => state?.currentUser?.currentUser);
  const profileImage = user?.profile_image || '';

  useEffect(() => {
    if (user) {
      setFormData({
        email: user.email,
        contact_number: user.contact_number,
        account_manager: user.account_manager,
        convai_key: user.convai_key,
        profile_image: user.profile_image,
      });
      setSingleUser(user);
    }
  }, [user]);

  useEffect(() => {
    if (currentUser) {
      try {
        const accountId = localStorage.getItem('account_id');
        dispatch(fetchMyAccountInfo(accountId));
      }
      catch (error) {
        console.log("🚀 ~ useEffect ~ error:", error)
      }
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAvatarChange = async (event) => {
    const file = event.target.files[0];
    const accountId = localStorage.getItem('account_id');
    const profileId = localStorage.getItem('profile_id');
    const updatedProfile = {
      ...formData,
      profile_image: file,
      company_profile: profileId
    };

    try {
      const response = await dispatch(updateMyAccountInfo(accountId, updatedProfile));
      await dispatch(fetchMyAccountInfo(accountId));
      toast({
        title: "Updated",
        description: "Profile Image Updated Successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top"
      });
    }
    catch(error) {
      toast({
        title: "Failed",
        description: "Failed to update profile image, Please try again!",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top"
      });
    }
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate that all fields are filled
    if (!formData.email || !formData.contact_number || !formData.account_manager || !formData.convai_key) {
      toast({
        title: translate("validation-error"),
        description: translate("all-fields-required"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (currentUser) {
      console.log("🚀 ~ handleSubmit ~ currentUser:", currentUser)
      try {
        const accountId = localStorage.getItem('account_id');
        const profileId = localStorage.getItem('profile_id');
        const response = await dispatch(updateMyAccountInfo(accountId, { ...formData, company_profile: profileId }));
        await dispatch(fetchMyAccountInfo(accountId));
        toast({
          title: "Updated",
          description: "Profile Updated Successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top"
        });
      }
      catch (error) {
        console.log("🚀 ~ handleSubmit ~ error:", error)
        toast({
          title: "Failed",
          description: "Failed to update profile, Please try again!",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top"
        });
      }
    }
  };

  return (
    <>
      <Grid
        templateColumns={{
          base: "1fr",
          lg: "1fr",
        }}
        mt={20}
        templateRows={{
          base: "1fr",
          lg: "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
      >

        <Card
          w="100%"
          gridArea="1 / 1 / 2 / 2"
          mb={{ base: "0px", lg: "20px" }}
          align='center'
        >
          {/* Profile Banner */}
          <Banner
            gridArea="1 / 1 / 2 / 2"
            banner={banner}
            avatar={profileImage || avatar}
            onAvatarChange={handleAvatarChange}
          />
          {/* <Heading as="h2" size="lg" textAlign="center" mb={6} color="gray.700"
          className={language === 'ar' && "arabic_font"}
          >
            {translate("update-profile")}
          </Heading> */}
          <form onSubmit={handleSubmit}>
            <VStack spacing={4}>
              <FormControl id="company_name" isRequired>
                <FormLabel textAlign={language === 'en' ? "left" : "right"} className={language === 'ar' && "arabic_font"}>{translate("email")}</FormLabel>
                <Input
                  className={language === 'ar' && "arabic_font"}
                  textAlign={language === 'en' ? "left" : "right"}
                  readOnly={true}
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder={translate("email")}
                  leftIcon={<Icon as={FaBuilding} />}
                />
              </FormControl>
              <FormControl id="contact_number" isRequired>
                <FormLabel textAlign={language === 'en' ? "left" : "right"} className={language === 'ar' && "arabic_font"}>{translate("contact-number")}</FormLabel>
                <Input
                  className={language === 'ar' && "arabic_font"}
                  textAlign={language === 'en' ? "left" : "right"}
                  type="tel"
                  name="contact_number"
                  value={formData.contact_number}
                  onChange={handleChange}
                  placeholder={translate("contact-number")}
                  leftIcon={<Icon as={FaPhone} />}
                />
              </FormControl>
              <FormControl id="account_manager" isRequired>
                <FormLabel textAlign={language === 'en' ? "left" : "right"} className={language === 'ar' && "arabic_font"}>{translate("account-manager")}</FormLabel>
                <Input
                  className={language === 'ar' && "arabic_font"}
                  textAlign={language === 'en' ? "left" : "right"}
                  type="text"
                  name="account_manager"
                  value={formData.account_manager}
                  onChange={handleChange}
                  placeholder={translate("account-manager")}
                  leftIcon={<Icon as={FaIndustry} />}
                />
              </FormControl>
              <FormControl id="convai_key" isRequired>
                <FormLabel textAlign={language === 'en' ? "left" : "right"} className={language === 'ar' && "arabic_font"}>{translate("convai-key")}</FormLabel>
                <Input
                  className={language === 'ar' && "arabic_font"}
                  textAlign={language === 'en' ? "left" : "right"}
                  type="text"
                  name="convai_key"
                  value={formData.convai_key}
                  onChange={handleChange}
                  placeholder={translate("convai-key")}
                  leftIcon={<Icon as={FaIndustry} />}
                />
              </FormControl>
              <Button
                bg="#F69320"
                color="white"
                _hover={{ bg: "#F67332" }}
                type="submit" width="full"
                className={language === 'ar' && "arabic_font"}
              >
                {translate("update-profile")}
              </Button>
            </VStack>
          </form>
        </Card>
      </Grid>
    </>
  );
};

export default Overview;
