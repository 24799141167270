import React, { useState, useEffect, useContext } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  IconButton,
  useDisclosure,
  Flex,
} from '@chakra-ui/react';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';
import CompanyFormModal from './CompanyFormModal';
import Card from "components/card/Card";
import { useSelector, useDispatch } from 'react-redux';
import { fetchCompanyProfiles, deleteCompanyProfile } from "actions/profileAction";
import { useHistory } from "react-router-dom";
import { useProfile } from 'context/CompanyContext';
import ConfirmationModal from 'components/confirmation/ConfirmationModal'; 
import { LanguageContext } from "context/LanguageContext";

const CompanyTable = () => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const history = useHistory();
  const { setProfile } = useProfile();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false); 
  const [profileToDelete, setProfileToDelete] = useState(null);

  const { isOpen: isFormOpen, onOpen: onFormOpen, onClose: onFormClose } = useDisclosure();
  const dispatch = useDispatch();
  const profilesData = useSelector(state => state.companyProfiles.companyProfiles);
  const allCompanies = useSelector(state => state.user.users);
  const { translate, language } = useContext(LanguageContext);

  useEffect(() => {
    dispatch(fetchCompanyProfiles()); // Fetch users to populate the table    
  }, [dispatch]);

  useEffect(() => {
    if (profilesData.length > 0) {
      setCompanies(profilesData);
    }
  }, [profilesData]);

  const handleEdit = (company) => {
    setSelectedCompany(company);
    onFormOpen();
  };

  const handleDeleteClick = (profileId) => {
    setProfileToDelete(profileId); // Set the account to delete
    setDeleteModalOpen(true); // Open the delete confirmation modal
  };

  const handleDelete = async (id) => {
    try {
      await dispatch(deleteCompanyProfile(profileToDelete));
    } catch (error) {
      console.error('Failed to delete company profile:', error);
    }
    setCompanies(companies.filter((company) => company.id !== profileToDelete));
    setDeleteModalOpen(false); // Close the delete confirmation modal
    setProfileToDelete(null); // Reset the account to delete
  };

  const handleCreate = () => {
    setSelectedCompany(null); // Clear selected company for creating a new one
    onFormOpen();
  };

  const handleView = (company) => {
    setSelectedCompany(company);
    setProfile(company);
    history.push("/admin/view-profile-section", { state: { company } }); // Navigate to the new route with the company data
  };

  const getCompanyNameById = (companyId) => {
    const companyName = allCompanies.find(company => company.id === companyId);
    return companyName ? companyName.company_name : "Unknown Company";
  };

  return (
    <>
      <Flex justifyContent="flex-end" mb={4} mt='80px'>
        <Button 
        bg="#F69320" 
        color="white" 
        _hover={{ bg: "#F67332"}}
        w="20%" onClick={handleCreate}
        className={language === 'ar' && "arabic_font"}
        >
          {translate("btn-create-comp-profile")}
        </Button>
      </Flex>

      <Card direction='column' w='100%' px='0px' overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th className={language === 'ar' && "arabic_font"}>{translate("company")}</Th>
              <Th className={language === 'ar' && "arabic_font"}>{translate("founders")}</Th>
              <Th className={language === 'ar' && "arabic_font"}>{translate("projects")}</Th>
              <Th className={language === 'ar' && "arabic_font"}>{translate("active")}</Th>
              <Th className={language === 'ar' && "arabic_font"}>{translate("column-header-action")}</Th>
              <Th className={language === 'ar' && "arabic_font"}>{translate("details")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {companies && companies.map((company) => (
              <Tr key={company.id}>
                <Td>{getCompanyNameById(company.company)}</Td>
                <Td>{company.founders}</Td>
                <Td>{company.company_projects}</Td>
                <Td>{company.is_active ? 'Yes' : 'No'}</Td>
                <Td>
                  <Flex>
                  <IconButton
                    aria-label="Edit Company"
                    icon={<EditIcon />}
                    onClick={() => handleEdit(company)}
                  />
                  <IconButton
                    aria-label="Delete Company"
                    icon={<DeleteIcon />}
                    onClick={() => handleDeleteClick(company.id)}
                  />
                  </Flex>
                </Td>
                <Td>
                  <Button size="sm" 
                  bg="#F69320" 
                  color="white" 
                  _hover={{ bg: "#F67332"}}
                  onClick={() => handleView(company)}
                  className={language === 'ar' && "arabic_font"}
                  >
                    {translate("view")}
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        <CompanyFormModal
          isOpen={isFormOpen}
          onClose={onFormClose}
          company={selectedCompany}
          setCompanies={setCompanies}
          companies={companies}
        />

        {/* Delete Confirmation Modal */}
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={handleDelete}
        title={translate("delete-profile")}
        message={translate("delete-profile-text")}
      />
      </Card>
    </>
  );
};

export default CompanyTable;
