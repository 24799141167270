
// reducers/userReducer.js
const initialState = {
    loading: false,
    knowledgeBank: [],
    error: '',
    checkDocumentStatusLoading: false,
    isAvailable: '',
};

const knowledgeBankReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_KNOWLEDGE_BANK_REQUEST':
            return {
                ...state,
                loading: true
            };
        case 'FETCH_KNOWLEDGE_BANK_SUCCESS':
            return {
                ...state,
                loading: false,
                knowledgeBank: action.payload,
                error: ''
            };
        case 'FETCH_KNOWLEDGE_BANK_FAILURE':
            return {
                ...state,
                loading: false,
                users: [],
                error: action.payload
            };
            case 'FETCH_CHECK_DOCUMENT_REQUEST':
            return {
                ...state,
                checkDocumentStatusLoading: true
            };
        case 'FETCH_CHECK_DOCUMENT_SUCCESS':
            return {
                ...state,
                checkDocumentStatusLoading: false,
                isAvailable: action.payload,
                error: ''
            };
        case 'FETCH_CHECK_DOCUMENT_FAILURE':
            return {
                ...state,
                checkDocumentStatusLoading: false,
                users: [],
                error: action.payload
            };
        case 'CREATE_KNOWLEDGE_BANK_REQUEST':
            return {
                ...state,
                loading: true
            };
        case 'CREATE_KNOWLEDGE_BANK_SUCCESS':
            return {
                ...state,
                loading: false,
                error: ''
            };
        case 'CREATE_KNOWLEDGE_BANK_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

export default knowledgeBankReducer;
