import React, { useState, useContext } from 'react';
import { Box, Button, Flex, VStack, Heading, Image, Spinner } from "@chakra-ui/react";
import { useSelector } from 'react-redux';
import LineSpinnerComponent from 'components/spinner';
import { LanguageContext } from 'context/LanguageContext';

const StepFive = ({ formData, setFormData, prevStep, submitForm }) => {
    const [loading, setLoading] = useState(true);
    const convAI = useSelector(state => state?.avatar?.characterDetail);
    console.log("🚀 ~ MultiStepForm ~ convAI:", convAI);
    const isLoading = useSelector(state => state?.avatar?.loading)
    const errorMessage = useSelector(state => state?.avatar?.error)
    const ImageAvatar = formData.avatar_placeholder;
    const { translate, language } = useContext(LanguageContext);      

    const handleImageLoad = () => {
        setLoading(false);
    };

    const launchHologram = () => {
        window.location.href = `Environment/index.html?characterId=${convAI[0]?.character_id}`;
    }

    return (
        <VStack spacing={4}>
            <Heading as="h2" size="lg" textAlign="center" mb={6} className={language === 'ar' && "arabic_font"}>
                {translate("avatar")}
            </Heading>
            <Box position="relative" mb={6}>
                {loading && (
                    <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
                        <LineSpinnerComponent />
                    </Box>
                )}
                <Image
                    src={ImageAvatar}
                    alt="Avatar Image"
                    boxSize="350px"
                    objectFit="cover"
                    onLoad={handleImageLoad}  // Set loading to false when image loads
                />
            </Box>
            <Flex>
                {errorMessage && <Button onClick={prevStep} mr={2} bg="gray.500" color="gray.100" _hover={{ bg: "gray.700" }}>Back</Button>}
                {convAI?.length === 0 ? (
                    <Button onClick={submitForm}
                        bg="#F69320"
                        color="white"
                        _hover={{ bg: "#F67332" }}
                        disabled={isLoading}
                        className={language === 'ar' && "arabic_font"}
                    >{translate("create-character")}</Button>
                ) : (
                    <Button onClick={submitForm}
                        bg="#F69320"
                        color="white"
                        _hover={{ bg: "#F67332" }}
                        disabled={isLoading}
                        className={language === 'ar' && "arabic_font"}
                        >{translate("update-character")}</Button>
                )}
            </Flex>
        </VStack>
    );
}

export default StepFive;
