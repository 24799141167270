// CompanyContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';

const CompanyContext = createContext();

export const CompanyProvider = ({ children }) => {
  const [profile, setProfile] = useState(() => {
    // Retrieve the profile from localStorage if available
    const savedProfile = localStorage.getItem('profile');
    return savedProfile ? JSON.parse(savedProfile) : null;
  });

  useEffect(() => {
    // Whenever profile changes, save it to localStorage
    if (profile) {
      localStorage.setItem('profile', JSON.stringify(profile));
    } else {
      localStorage.removeItem('profile');
    }
  }, [profile]);

  return (
    <CompanyContext.Provider value={{ profile, setProfile }}>
      {children}
    </CompanyContext.Provider>
  );
};

export const useProfile = () => useContext(CompanyContext);
