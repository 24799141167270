// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Noto+Naskh+Arabic:wght@100;200;300;400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: "DM Sans", sans-serif;
}

.arabic_font{
  font-family: "Noto Naskh Arabic", sans-serif;
  direction: rtl;  /* Sets the direction to right-to-left */
  text-align: right;
}

.arabic_font input {
  direction: rtl;
  text-align: right;
}

.arabic_font input::placeholder {
  text-align: right; /* Aligns the placeholder text to the right */
}

option {
  color: black;
}

.react-datepicker-wrapper{
  display: block !important;
}

.lang-btn {
  background: transparent;
  padding: 4px 15px;
  border-radius: 2rem;
  margin-top: 10px;
}

.btn-light-border {
  margin-right: 40px;
  background: transparent;
  border: 2px solid #E1E9F8;
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/App.css"],"names":[],"mappings":"AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,4CAA4C;EAC5C,cAAc,GAAG,wCAAwC;EACzD,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,iBAAiB,EAAE,6CAA6C;AAClE;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;EACvB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,uBAAuB;EACvB,yBAAyB;EACzB,YAAY;AACd","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap\");\n@import url(\"https://fonts.googleapis.com/css2?family=Noto+Naskh+Arabic:wght@100;200;300;400;500;600;700;800;900&display=swap\");\nbody {\n  font-family: \"DM Sans\", sans-serif;\n}\n\n.arabic_font{\n  font-family: \"Noto Naskh Arabic\", sans-serif;\n  direction: rtl;  /* Sets the direction to right-to-left */\n  text-align: right;\n}\n\n.arabic_font input {\n  direction: rtl;\n  text-align: right;\n}\n\n.arabic_font input::placeholder {\n  text-align: right; /* Aligns the placeholder text to the right */\n}\n\noption {\n  color: black;\n}\n\n.react-datepicker-wrapper{\n  display: block !important;\n}\n\n.lang-btn {\n  background: transparent;\n  padding: 4px 15px;\n  border-radius: 2rem;\n  margin-top: 10px;\n}\n\n.btn-light-border {\n  margin-right: 40px;\n  background: transparent;\n  border: 2px solid #E1E9F8;\n  color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
