import React, { useState, useEffect } from 'react';
import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Select,
    IconButton,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure,
    Box,
    HStack,
    useToast,
} from '@chakra-ui/react';
import { DeleteIcon, AddIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from 'react-redux';
import { fetchKnowledgeBank, createKnowledgeBank, checkDocumentStatus, connectKnowledgeBank } from 'actions/knowledgeBankActions';

const translations = {
    en: {
        question: "Question",
        answer: "Answer",
        addRow: "Add Question!",
        save: "Save",
        addNewRow: "Add New Question",
        selectQuestionType: "Select Question Type",
        single: "Single",
        tree: "Tree",
        cancel: "Cancel",
        connect: "Connect",
        txtToggleButton: "English Language"
    },
    ar: {
        question: "سؤال",
        answer: "إجابة",
        addRow: "أضف صفًا!",
        save: "حفظ",
        addNewRow: "إضافة صف جديد",
        selectQuestionType: "اختر نوع السؤال",
        single: "فردي",
        tree: "شجرة",
        cancel: "إلغاء",
        connect: "يتصل",
        txtToggleButton: "اللغة العربية"
    }
};

const KnowledgeBank = () => {
    const [language, setLanguage] = useState('ar');
    const [rows, setRows] = useState([]);
    const [newRow, setNewRow] = useState({ id: '', question_type: '', text: '', answer_text: '', speaker: '', children: [], question_language: language });
    const { isOpen, onOpen, onClose } = useDisclosure();
    const dispatch = useDispatch();
    const toast = useToast();
    const { knowledgeBank, loading, isAvailable, checkDocumentStatusLoading } = useSelector((state) => state?.knowledgeBank);

    useEffect(() => {
        dispatch(fetchKnowledgeBank());
        dispatch(checkDocumentStatus());
    }, []);

    useEffect(() => {
        setRows(knowledgeBank);
    }, [knowledgeBank]);

    const handleInputChange = (id, field, value) => {
        const updatedRows = rows.map(row => row.id === id ? { ...row, [field]: value } : row);
        setRows(updatedRows);
    };

    const handleNewRowChange = (field, value) => {
        setNewRow({ ...newRow, [field]: value, question_language: language });
    };

    const addRow = () => {
        setRows([...rows, newRow]);
        setNewRow({id: Date.now(), question_type: '', text: '', answer_text: '', speaker: '', children: [], question_language: language });
        onClose();
    };

    const deleteRow = (id) => {
        const updatedRows = rows.filter(row => row.id !== id);
        setRows(updatedRows);
    };

    const deleteChild = (id) => {
        const updatedRows = rows.map(row => ({
            ...row,
            children: row.children.filter(child => child.id !== id)
        }));
        setRows(updatedRows);
    };

    const saveKnowledgeBank = () => {
        console.log(rows);
        dispatch(createKnowledgeBank(rows, toast));
    };

    const addChild = (id) => {
        const updatedRows = rows.map(row => 
            row.id === id && row.question_type === 'tree' && row.question_language === language ? { ...row, children: [...row.children, { id: Date.now(), question_type: 'horizontal', text: '', answer_text: '', speaker: '' }] } : row
        );
        setRows(updatedRows);
    };

    const handleChildInputChange = (parentId, childId, field, value) => {
        const updatedRows = rows.map(row => 
            row.id === parentId ? {
                ...row,
                children: row.children.map(child => child.id === childId ? { ...child, [field]: value } : child)
            } : row
        );
        setRows(updatedRows);
    };

    const toggleLanguage = () => {
        setLanguage((prevLanguage) => (prevLanguage === 'en' ? 'ar' : 'en'));
    };

    const handleConnectDocument = () => {
        dispatch(connectKnowledgeBank());
    };

    const handleCloseModal = () => {
        // Clear the states when closing the modal without saving
        setNewRow({ id: '', question_type: '', text: '', answer_text: '', speaker: '', children: [], question_language: language });
        onClose(); // Call the onClose prop to close the modal
      };

    const filteredRows = rows.filter(row => row.question_language === language);
    console.log("🚀 ~ KnowledgeBank ~ rows:", rows)
    console.log("🚀 ~ KnowledgeBank ~ filteredRows:", filteredRows)

    return (
        <Box mx="auto" mt={20} p={6} borderWidth={1} borderRadius="lg" bg="white">
            <Box display={{ sm: "none", lg: "flex" }} spacing='12px' mt={4}>
                <Button onClick={toggleLanguage} mb={4} 
                bg="#F69320" 
                color="white" 
                _hover={{ bg: "#F67332" }}
                className={language === 'ar' && "arabic_font"}
                >
                    {translations[language].txtToggleButton}
                </Button>
                <Button onClick={onOpen} 
                bg="#EF4959" 
                color="white" 
                _hover={{ bg: "#E43D50" }}
                 ml="auto" disabled={loading}
                 className={language === 'ar' && "arabic_font"}
                 >{translations[language].addRow}</Button>
                <Button onClick={handleConnectDocument} 
                bg="#EF4959" 
                color="white" 
                _hover={{ bg: "#E43D50" }}
                ml="auto" disabled={!isAvailable.is_available || checkDocumentStatusLoading}
                className={language === 'ar' && "arabic_font"}
                >{translations[language].connect}</Button>
            </Box>
            {filteredRows.map((row, index) => (
                (row.question_type == 'tree' || row.question_type == 'simple') ? (
                    <Box key={row.id} mb={4} border="1px" borderColor="gray.300" p={4} borderRadius="md">
                        <Flex gap={6} alignItems="center">
                            <FormControl>
                                <FormLabel className={language === 'ar' && "arabic_font"}>{translations[language].question}</FormLabel>
                                <Input
                                className={language === 'ar' && "arabic_font"}
                                    value={row.text}
                                    onChange={(e) => handleInputChange(row.id, 'text', e.target.value)}
                                    maxLength={250}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel className={language === 'ar' && "arabic_font"}>{translations[language].answer}</FormLabel>
                                <Input
                                className={language === 'ar' && "arabic_font"}
                                    value={row.answer_text}
                                    onChange={(e) => handleInputChange(row.id, 'answer_text', e.target.value)}
                                    maxLength={250}
                                />
                            </FormControl>
                            {row.question_type === 'tree' && (
                                <IconButton
                                    size="sm"
                                    aria-label="Add Child"
                                    icon={<AddIcon />}
                                    onClick={() => addChild(row.id)}
                                    colorScheme="teal"
                                />
                            )}
                            <IconButton
                                size="sm"
                                aria-label="Delete row"
                                icon={<DeleteIcon />}
                                onClick={() => deleteRow(row.id)}
                                colorScheme="red"
                            />
                        </Flex>
                        {row.children && row.children.map((child, childIndex) => (
                            <Flex key={child.id} gap={6} alignItems="center" ml={10} mt={4}>
                                <FormControl>
                                    <FormLabel className={language === 'ar' && "arabic_font"}>{translations[language].question}</FormLabel>
                                    <Input
                                        value={child.text}
                                        onChange={(e) => handleChildInputChange(row.id, child.id, 'text', e.target.value)}
                                        maxLength={250}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel className={language === 'ar' && "arabic_font"}>{translations[language].answer}</FormLabel>
                                    <Input
                                        value={child.answer_text}
                                        onChange={(e) => handleChildInputChange(row.id, child.id, 'answer_text', e.target.value)}
                                        maxLength={250}
                                    />
                                </FormControl>
                                <IconButton
                                    size="sm"
                                    aria-label="Delete child row"
                                    icon={<DeleteIcon />}
                                    onClick={() => deleteChild(child.id)}
                                    colorScheme="red"
                                />
                            </Flex>
                        ))}
                    </Box>
                ) : (
                    <Box key={index} mb={4}>
                        {row.children && row.children.map((child, childIndex) => (
                            <Flex key={child.id} gap={6} alignItems="center" ml={10} mt={4}>
                                <FormControl>
                                    <FormLabel className={language === 'ar' && "arabic_font"}>{translations[language].question}</FormLabel>
                                    <Input
                                        value={child.text}
                                        onChange={(e) => handleChildInputChange(row.id, child.id, 'text', e.target.value)}
                                        maxLength={250}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel className={language === 'ar' && "arabic_font"}>{translations[language].answer}</FormLabel>
                                    <Input
                                        value={child.answer_text}
                                        onChange={(e) => handleChildInputChange(row.id, child.id, 'answer_text', e.target.value)}
                                        maxLength={250}
                                    />
                                </FormControl>
                                <IconButton
                                    size="sm"
                                    aria-label="Delete child row"
                                    icon={<DeleteIcon />}
                                    onClick={() => deleteChild(child.id)}
                                    colorScheme="red"
                                />
                            </Flex>
                        ))}
                    </Box>
                )
            ))}

            <HStack display={{ sm: "none", lg: "flex" }} spacing='12px' mt={4}>
                <Button onClick={saveKnowledgeBank} ml={1} 
                bg="#F69320" 
                color="white" 
                _hover={{ bg: "#F67332" }}
                className={language === 'ar' && "arabic_font"}
                disabled={loading}>{translations[language].save}</Button>
            </HStack>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader className={language === 'ar' && "arabic_font"}>{translations[language].addNewRow}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel className={language === 'ar' && "arabic_font"}>{translations[language].selectQuestionType}</FormLabel>
                            <Select
                                value={newRow.question_type}
                                onChange={(e) => handleNewRowChange('question_type', e.target.value)}
                            >
                                <option value="" className={language === 'ar' && "arabic_font"} disabled>{translations[language].selectQuestionType}</option>
                                <option value="simple" className={language === 'ar' && "arabic_font"}>{translations[language].single}</option>
                                <option value="tree" className={language === 'ar' && "arabic_font"}>{translations[language].tree}</option>
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel className={language === 'ar' && "arabic_font"}>{translations[language].question}</FormLabel>
                            <Input
                                value={newRow.text}
                                onChange={(e) => handleNewRowChange('text', e.target.value)}
                                maxLength={250}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel className={language === 'ar' && "arabic_font"}>{translations[language].answer}</FormLabel>
                            <Input
                                value={newRow.answer_text}
                                onChange={(e) => handleNewRowChange('answer_text', e.target.value)}
                                maxLength={250}
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button 
                        bg="#F69320" 
                        color="white" 
                        _hover={{ bg: "#F67332" }}
                        mr={3} onClick={addRow}
                        disabled={!newRow.question_type ||!newRow.text ||!newRow.answer_text}
                        className={language === 'ar' && "arabic_font"}
                        >
                            {translations[language].save}
                        </Button>
                        <Button variant="ghost" onClick={handleCloseModal} className={language === 'ar' && "arabic_font"}>
                            {translations[language].cancel}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default KnowledgeBank;
