import axiosInstance from '../axiosConfig';

export const loginCurrentUsser = (currentUser) => {
    return (dispatch) => {
        dispatch({
            type: 'LOGIN_CURRENT_USER_REQUEST',
        });
        return axiosInstance.post('/accounts/token/', currentUser)
            .then(response => {
                const currentUser = response.data;
                dispatch({
                    type: 'LOGIN_CURRENT_USER_SUCCESS',
                    payload: currentUser,
                });
                localStorage.setItem('token', currentUser?.access);
                localStorage.setItem('role', currentUser?.user?.role);
                localStorage.setItem('account_id', currentUser?.account_id);
                localStorage.setItem('profile_id', currentUser?.profile_id);
                localStorage.setItem('company_id', currentUser?.company_id);
                return currentUser;
            })
            .catch(error => {
                const errorMsg = error.message;
                dispatch({
                    type: 'LOGIN_CURRENT_USER_FAILURE',
                    payload: errorMsg,
                });
                throw error; // rethrow the error to be caught in the component
            });
    };
};

export const logoutCurrentUser = () => {
    return (dispatch) => {
        dispatch({
            type: 'LOGOUT_CURRENT_USER',
        });
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        localStorage.removeItem('account_id');
        localStorage.removeItem('profile_id');
        localStorage.removeItem('company_id');
    };
};