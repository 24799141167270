// src/contexts/LanguageContext.js
import React, { createContext, useState } from "react";
import en from "../locales/en.json";
import ar from "../locales/ar.json";

export const LanguageContext = createContext();

const translations = {
  en,
  ar,
};

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState( localStorage.getItem("lang") || "en");

  const translate = (key) => {
    return translations[language][key] || key;
  };

  const switchLanguage = (lang) => {
    localStorage.setItem("lang", lang)
    setLanguage(lang);
  };

  return (
    <LanguageContext.Provider value={{ translate, switchLanguage, language }}>
      {children}
    </LanguageContext.Provider>
  );
};
