import React, { useEffect, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Avatar, Box, VStack, Image, Heading, Button, Flex, HStack, Spinner } from '@chakra-ui/react';
import { fetchAvatar, fetchConvaiCharacter } from 'actions/avatarActions';
import LineSpinnerComponent from 'components/spinner';
import { LanguageContext } from 'context/LanguageContext';

const AvatarComponent = () => {
    const dispatch = useDispatch();
    const { translate, language } = useContext(LanguageContext);
    const stateData = useSelector(state => state?.avatar?.avatar);
    console.log("🚀 ~ AvatarComponent ~ stateData:", stateData)
    const convAI = useSelector(state => state?.avatar?.characterDetail);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        dispatch(fetchAvatar());
        dispatch(fetchConvaiCharacter());
    }, [])
    const launchHologram = () => {
        window.location.href = `Environment/index.html?characterId=${convAI[0]?.character_id}`;
    }

    const handleImageLoad = () => {
        setLoading(false);
    };
    
    return (
        <Box mx="auto" mt={20} p={6} borderWidth={1} borderRadius="lg" bg="white">
            <HStack spacing={8} justify="center">
                <Box>
                {loading && (
                    <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
                        <LineSpinnerComponent  />
                    </Box>
                )}
                    <VStack spacing={4}>
                        <Heading as="h2" size="lg" textAlign="center" mb={6} className={language === 'ar' && "arabic_font"}>
                            {translate("avatar")}
                        </Heading>
                        {convAI?.length > 0 && <Image
                            src={convAI[0]?.character_information?.model_details?.modelPlaceholder}
                            alt="Avatar Image"
                            boxSize="350px"
                            objectFit="cover"
                            mb={6}
                            onLoad={handleImageLoad}
                        />}
                    </VStack>
                    <Box textAlign="center" mt={1.5}>
                        <Button onClick={launchHologram} 
                        bg="#F69320" 
                        color="white" 
                        _hover={{ bg: "#F67332" }}
                        className={language === 'ar' && "arabic_font"}
          >{translate("launch-hologram")}</Button>
                    </Box>
                </Box>
            </HStack>
        </Box>
    );
};

export default AvatarComponent;
