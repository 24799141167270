import {
  Avatar,
  Box,
  Flex,
  Text,
  useColorModeValue,
  IconButton,
  Input,
} from "@chakra-ui/react";
import { FiCamera } from "react-icons/fi";
import Card from "components/card/Card.js";
import React, { useRef, useContext } from "react";
import { LanguageContext } from "context/LanguageContext";

export default function Banner(props) {
  const {
    banner,
    avatar,
    name,
    company_name,
    industry,
    size,
    active,
    onAvatarChange,
  } = props;

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue("white !important", "#111C44 !important");
  const { translate, language } = useContext(LanguageContext);

  // Ref for file input
  const inputRef = useRef(null);

  // Handle file input click
  const handleClick = () => {
    inputRef.current.click();
  };

  return (
    <Card mb={{ base: "0px", lg: "20px" }} align="center">
      <Box
        bg={`url(${banner})`}
        bgSize="cover"
        borderRadius="16px"
        h="131px"
        w="100%"
      />
      <Box position="relative" mt="-43px" maxW="87px" margin="-43px auto 0">
        <Avatar
          mx="auto"
          src={avatar}
          h="87px"
          w="87px"
          border="4px solid"
          borderColor={borderColor}
        />
        <Box position="absolute" bottom="0" right="-10px">
          <Input
            type="file"
            accept="image/*"
            onChange={onAvatarChange}
            opacity="0"
            position="absolute"
            inset="0"
            cursor="pointer"
            ref={inputRef}
          />
          <IconButton
            icon={<FiCamera />}
            isRound
            size="sm"
            aria-label="Upload Avatar"
            onClick={handleClick}
          />
        </Box>
      </Box>
      <Text color={textColorPrimary} fontWeight="bold" fontSize="xl" mt="10px">
        {name}
      </Text>
      <Text color={textColorSecondary} fontSize="sm">
        {company_name}
      </Text>
      <Flex w="max-content" mx="auto" mt="26px">
        <Flex mx="auto" me="60px" align="center" direction="column">
          <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
            {industry}
          </Text>
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400" 
          className={language === 'ar' && "arabic_font"}>
            {translate("industry")}
          </Text>
        </Flex>
        <Flex mx="auto" me="60px" align="center" direction="column">
          <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
            {size}
          </Text>
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400" 
          className={language === 'ar' && "arabic_font"}
          >
            {translate("txt-comp-size")}
          </Text>
        </Flex>
        <Flex mx="auto" align="center" direction="column">
          <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
            {active}
          </Text>
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400" 
          className={language === 'ar' && "arabic_font"}
          >
            {translate(("active"))}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
}
