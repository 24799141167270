const initialState = {
    loading: false,
    narrativeData: {},
    error: ''
  };
  
  const narrativeReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_NARRATIVE_REQUEST':
        return {
          ...state,
          loading: true
        };
      case 'FETCH_NARRATIVE_SUCCESS':
        return {
            ...state,
          loading: false,
          narrativeData: action.payload,
          error: ''
        };
      case 'FETCH_NARRATIVE_FAILURE':
        return {
            ...state,
          loading: false,
          narrativeData: {},
          error: action.payload
        };
        case 'CREATE_NARRATIVE_REQUEST':
        return {
          ...state,
          loading: true
        };
      case 'CREATE_NARRATIVE_SUCCESS':
        return {
            ...state,
          loading: false,
          error: ''
        };
      case 'CREATE_NARRATIVE_FAILURE':
        return {
            ...state,
          loading: false,
          error: action.payload
        };
        case 'DELETE_NARRATIVE_REQUEST':
        return {
          ...state,
          loading: true
        };
      case 'DELETE_NARRATIVE_SUCCESS':
        return {
            ...state,
          loading: false,
          error: ''
        };
      case 'DELETE_NARRATIVE_FAILURE':
        return {
            ...state,
          loading: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
  
  export default narrativeReducer;
  