import axiosInstance from '../axiosConfig';

export const fetchGeneralKnowledgeBank = (profile_id) => {
    return (dispatch) => {
        dispatch({
            type: 'FETCH_GENERAL_KNOWLEDGE_BANK_REQUEST'
        });
        const formData = new FormData();
        formData.append('profile_id', profile_id);
        axiosInstance.post('/avatars/questions/get_knowledege_base_for_company/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                const knowledgeBank = response.data;
                dispatch({
                    type: 'FETCH_GENERAL_KNOWLEDGE_BANK_SUCCESS',
                    payload: knowledgeBank
                });
            })
            .catch(error => {
                const errorMsg = error.message;
                dispatch({
                    type: 'FETCH_GENERAL_KNOWLEDGE_BANK_FAILURE',
                    payload: errorMsg
                });
            });
    };
};


export const createGeneralKnowledgeBank = (knowledgeBankDetails, profile_id) => {
    return (dispatch) => {
        dispatch({
            type: 'CREATE_GENERAL_KNOWLEDGE_BANK_REQUEST'
        });
        return axiosInstance.post('/avatars/questions/bulk_create_for_company/', knowledgeBankDetails)
            .then(response => {
                const knowledgeBank = response.data;
                dispatch({
                    type: 'CREATE_GENERAL_KNOWLEDGE_BANK_SUCCESS',
                    payload: knowledgeBank
                });
                dispatch(fetchGeneralKnowledgeBank(profile_id));
                return knowledgeBank;
            })
            .catch(error => {
                const errorMsg = error.message;
                dispatch({
                    type: 'CREATE_GENERAL_KNOWLEDGE_BANK_FAILURE',
                    payload: errorMsg
                });
                throw error;
            });
    };
};