import React, { useState, useEffect, useContext } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
  Switch,
  useToast,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers, createUser } from "actions/userActions";
import { createCompanyProfile, updateCompanyProfile } from "actions/profileAction";
import { LanguageContext } from "context/LanguageContext";

const CompanyFormModal = ({ isOpen, onClose, company, setCompanies, companies }) => {
  const [formData, setFormData] = useState({
    company: '',
    activities: '',
    foundation_details: '',
    founders: '',
    company_mission: '',
    company_revenue: '',
    company_certificates: '',
    company_projects: '',
    company_collaboration: '',
    company_commitments: '',
    company_challenges: '',
    extra_info: {},
    activities_ar: '',
    foundation_details_ar: '',
    founders_ar: '',
    company_mission_ar: '',
    company_revenue_ar: '',
    company_certificates_ar: '',
    company_projects_ar: '',
    company_collaboration_ar: '',
    company_commitments_ar: '',
    company_challenges_ar: '',
    is_active: false,
    avatar: 2,
  });

  const dispatch = useDispatch();
  const tableDataColumns = useSelector(state => state.user.users);
  const toast = useToast();
  const { translate, language } = useContext(LanguageContext);

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  useEffect(() => {
    if (company) {
      setFormData(company);
    } else {
      setFormData({
        company: '',
        activities: '',
        foundation_details: '',
        founders: '',
        company_mission: '',
        company_revenue: '',
        company_certificates: '',
        company_projects: '',
        company_collaboration: '',
        company_commitments: '',
        company_challenges: '',
        extra_info: {},
        activities_ar: '',
        foundation_details_ar: '',
        founders_ar: '',
        company_mission_ar: '',
        company_revenue_ar: '',
        company_certificates_ar: '',
        company_projects_ar: '',
        company_collaboration_ar: '',
        company_commitments_ar: '',
        company_challenges_ar: '',
        is_active: false,
        avatar: 2,
      });
    }
  }, [company]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (company) {
      console.log('Updaing new company', formData);
      setCompanies(companies.map(c => (c.id === company.id ? formData : c)));
      try {
        await dispatch(updateCompanyProfile(formData));
        toast({
          title: 'Updated!',
          description: 'Company updated successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
      } catch (error) {
        console.error('Failed to update company profile:', error);
        toast({
          title: 'Error!',
          description: 'Something went wrong. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
      }
    } else {
      console.log('Creating new company', formData);
      try {
        await dispatch(createCompanyProfile(formData));
        setCompanies([...companies, { ...formData, id: Date.now() }]);
        toast({
          title: 'Created!',
          description: 'Company created successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
      } catch (error) {
        toast({
          title: 'Error Occurred!',
          description: 'A profile for this company may already exist. Please verify your details and try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
        console.error('Failed to create company profile:', error);
      }

    }
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="900px">
        <ModalHeader className={language === 'ar' && "arabic_font"}
          style={language === 'ar' ? { textAlign: 'right', marginRight: '25px' } : {}}
        >{company ? translate("btn-edit-comp-profile") : translate("btn-create-comp-profile")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Company Selection */}
          <FormControl mb={4}>
            <FormLabel>Company</FormLabel>
            <Select
              name="company"
              value={formData.company}
              onChange={handleChange}
              placeholder="Select a company"
            >
              {tableDataColumns && tableDataColumns.map((company) => (
                <option key={company.id} value={company.id}>
                  {company.company_name}
                </option>
              ))}
            </Select>
          </FormControl>


          {/* English Fields */}
          <FormControl mb={4}>
            <FormLabel>Activities</FormLabel>
            <Input
              name="activities"
              value={formData.activities}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Foundation Details</FormLabel>
            <Textarea
              name="foundation_details"
              value={formData.foundation_details}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Founders</FormLabel>
            <Input
              name="founders"
              value={formData.founders}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Company Mission</FormLabel>
            <Textarea
              name="company_mission"
              value={formData.company_mission}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Company Revenue</FormLabel>
            <Input
              name="company_revenue"
              value={formData.company_revenue}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Company Certificates</FormLabel>
            <Input
              name="company_certificates"
              value={formData.company_certificates}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Company Projects</FormLabel>
            <Input
              name="company_projects"
              value={formData.company_projects}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Company Collaboration</FormLabel>
            <Input
              name="company_collaboration"
              value={formData.company_collaboration}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Company Commitments</FormLabel>
            <Input
              name="company_commitments"
              value={formData.company_commitments}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Company Challenges</FormLabel>
            <Textarea
              name="company_challenges"
              value={formData.company_challenges}
              onChange={handleChange}
            />
          </FormControl>

          {/* Arabic Fields */}
          <FormControl mb={4}>
            <FormLabel>Activities (Arabic)</FormLabel>
            <Input
              name="activities_ar"
              value={formData.activities_ar}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Foundation Details (Arabic)</FormLabel>
            <Textarea
              name="foundation_details_ar"
              value={formData.foundation_details_ar}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Founders (Arabic)</FormLabel>
            <Input
              name="founders_ar"
              value={formData.founders_ar}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Company Mission (Arabic)</FormLabel>
            <Textarea
              name="company_mission_ar"
              value={formData.company_mission_ar}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Company Revenue (Arabic)</FormLabel>
            <Input
              name="company_revenue_ar"
              value={formData.company_revenue_ar}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Company Certificates (Arabic)</FormLabel>
            <Input
              name="company_certificates_ar"
              value={formData.company_certificates_ar}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Company Projects (Arabic)</FormLabel>
            <Input
              name="company_projects_ar"
              value={formData.company_projects_ar}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Company Collaboration (Arabic)</FormLabel>
            <Input
              name="company_collaboration_ar"
              value={formData.company_collaboration_ar}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Company Commitments (Arabic)</FormLabel>
            <Input
              name="company_commitments_ar"
              value={formData.company_commitments_ar}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Company Challenges (Arabic)</FormLabel>
            <Textarea
              name="company_challenges_ar"
              value={formData.company_challenges_ar}
              onChange={handleChange}
            />
          </FormControl>

          {/* Extra Info and Avatar */}
          {/* <FormControl mb={4}>
            <FormLabel>Extra Info</FormLabel>
            <Textarea
              name="extra_info"
              value={formData.extra_info}
              onChange={handleChange}
            />
          </FormControl> */}

          {/* <FormControl mb={4}>
            <FormLabel>Avatar</FormLabel>
            <Input
              type="file"
              name="avatar"
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  // avatar: e.target.files[0],
                  avatar: 2,
                }))
              }
            />
          </FormControl> */}

          {/* Active Status */}
          <FormControl mb={4}>
            <FormLabel className={language === 'ar' && "arabic_font"}>{translate("active")}</FormLabel>
            <Switch
              name="is_active"
              isChecked={formData.is_active}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  is_active: e.target.checked,
                }))
              }
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            bg="#F69320"
            color="white"
            _hover={{ bg: "#F67332" }}
            mr={3} onClick={handleSubmit}
            className={language === 'ar' && "arabic_font"}
          >
            {translate("btn-save")}
          </Button>
          <Button onClick={onClose} className={language === 'ar' && "arabic_font"}>{translate("txt-comp-cancel")}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CompanyFormModal;
