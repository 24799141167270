const initialState = {
    loading: false,
    currentUser: {},
    error: '',
};

const currentUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN_CURRENT_USER_REQUEST':
            return {
                ...state,
                loading: true
            };
        case 'LOGIN_CURRENT_USER_SUCCESS':
            return {
                ...state,
                loading: false,
                currentUser: action.payload,
                error: ''
            };
        case 'LOGIN_CURRENT_USER_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case 'LOGOUT_CURRENT_USER':
            return {
                ...state,
                loading: false,
                currentUser: {},
                error: '',
            };
        default:
            return state;
    }
};

export default currentUserReducer;