// reducers/tutorialReducer.js
const initialState = {
    loading: false,
    tutorials: [],
    error: ''
  };
  
  const tutorialReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_TUTORIAL_REQUEST':
        return {
          ...state,
          loading: true
        };
      case 'FETCH_TUTORIAL_SUCCESS':
        return {
          loading: false,
          tutorials: action.payload,
          error: ''
        };
      case 'FETCH_TUTORIAL_FAILURE':
        return {
          loading: false,
          tutorials: [],
          error: action.payload
        };
        case 'UPLOAD_TUTORIAL_REQUEST':
        return {
          ...state,
          loading: true
        };
      case 'UPLOAD_TUTORIAL_SUCCESS':
        return {
          loading: false,
          error: ''
        };
      case 'UPLOAD_TUTORIAL_FAILURE':
        return {
          loading: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
  
  export default tutorialReducer;
  