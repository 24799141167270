import React, { useState, useEffect, useContext } from 'react';
import { Box, Image } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { fetchTutorial } from 'actions/tutorialAction';
import { LanguageContext } from 'context/LanguageContext';
import Card from "components/card/Card.js";

const TutorialVideo = () => {
  const [tutorial, setTutorial] = useState(null);
  const dispatch = useDispatch();
  const { translate, language } = useContext(LanguageContext);

  useEffect(() => {
    const fetchData = async () => {
      const tutorialResponse = await dispatch(fetchTutorial());
      const lastTutorial = tutorialResponse[tutorialResponse.length - 1];
      setTutorial(lastTutorial);
    };

    fetchData();
  }, [dispatch]);

  function capitalizeFirstLetterOfEachWord(str) {
    return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) 
      .join(' '); 
  }

  return (
    <>
      <Card backgroundRepeat='no-repeat'
        bgSize='cover'
        alignSelf='center'
        w={{ base: "100%", md: "60%", xl: "99%" }}
        bgPosition='10%'
        mx='auto'
        p='20px'
        mt={20}
        mb={4}>
        <Box textTransform="capitalize" as="h2" fontSize="2xl" textAlign="center" className={language === 'ar' && "arabic_font"}>
          {/* {translate("welcome")}       */}
          {tutorial?.title || translate("welcome")}
        </Box>
      </Card>
      <Card
        backgroundRepeat='no-repeat'
        bgSize='cover'
        alignSelf='center'
        w={{ base: "100%", md: "60%", xl: "99%" }}
        bgPosition='10%'
        mx='auto'
        p='20px'
        mb={4}
        mt={4}>
        <Box
          dangerouslySetInnerHTML={{ __html: tutorial?.text_content || '' }}
        />
      </Card>
      <Card backgroundRepeat='no-repeat'
        bgSize='cover'
        alignSelf='center'
        w={{ base: "100%", md: "60%", xl: "99%" }}
        bgPosition='10%'
        mx='auto'
        p='20px'
        mb={4}
        mt={4}>
        {tutorial && (
          <Box mb={4} border="1px" borderColor="gray.200" borderRadius="md" overflow="hidden">
            {tutorial.is_image && (
              <Image src={tutorial.media_file} alt="Tutorial Image" width="100%" />
            )}
            {tutorial.is_video && (
              <video width="100%" controls>
                <source src={tutorial.media_file} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </Box>
        )}
      </Card>
    </>
  );
};

export default TutorialVideo;
