import React, { useState, useEffect, useContext } from 'react';
import {
  Box, Input, Textarea, Button, FormControl, FormLabel, useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  HStack,
  Flex,
  Heading,
  IconButton,
  Switch,
} from '@chakra-ui/react';
import axios from 'axios';
import { updateUser, fetchUserById } from 'actions/userActions';
import { fetchProfileById, updateCompanyProfile } from 'actions/profileAction';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteIcon } from '@chakra-ui/icons'; 
import { LanguageContext } from 'context/LanguageContext';

const translations = {
  en: {
    addQuestion: 'Add a new question',
    language: 'Language',
    question: 'Question',
    enterQuestion: 'Enter your question',
    answer: 'Answer',
    enterAnswer: 'Enter the answer',
    save: 'Save',
    cancel: 'Cancel'
  },
  ar: {
    addQuestion: 'أضف سؤالًا جديدًا',
    language: 'اللغة',
    question: 'سؤال',
    enterQuestion: 'أدخل سؤالك',
    answer: 'إجابة',
    enterAnswer: 'أدخل الإجابة',
    save: 'حفظ',
    cancel: 'إلغاء'
  }
};

const CompanyInfoSection = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { translate, language } = useContext(LanguageContext);
  // const [language, setLanguage] = useState('ar');
  const [companyData, setCompanyData] = useState({
    activities: '',
    foundation_details: '',
    founders: '',
    company_mission: '',
    company_revenue: '',
    company_certificates: '',
    company_projects: '',
    company_collaboration: '',
    company_commitments: '',
    company_challenges: '',
    extra_info: {},
    activities_ar: '',
    foundation_details_ar: '',
    founders_ar: '',
    company_mission_ar: '',
    company_revenue_ar: '',
    company_certificates_ar: '',
    company_projects_ar: '',
    company_collaboration_ar: '',
    company_commitments_ar: '',
    company_challenges_ar: '',
    is_active: true,
  });

  const user = useSelector((state) => state.companyProfiles?.singleProfile);
  const [extraInfo, setExtraInfo] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const t = translations[language];

  useEffect(() => {
    if (user) {
      const extraInfoTemp = user.extra_info || {};
      const arrayresult = Object.entries(extraInfoTemp).map(([key, value]) => ({
        question: key,
        answer: value
      }));
      setCompanyData({
        activities: user.activities || '',
        foundation_details: user.foundation_details || '',
        founders: user.founders || '',
        company_mission: user.company_mission || '',
        company_revenue: user.company_revenue || '',
        company_certificates: user.company_certificates || '',
        company_projects: user.company_projects || '',
        company_collaboration: user.company_collaboration || '',
        company_commitments: user.company_commitments || '',
        company_challenges: user.company_challenges || '',
        activities_ar: user.activities_ar || '',
        foundation_details_ar: user.foundation_details_ar || '',
        founders_ar: user.founders_ar || '',
        company_mission_ar: user.company_mission_ar || '',
        company_revenue_ar: user.company_revenue_ar || '',
        company_certificates_ar: user.company_certificates_ar || '',
        company_projects_ar: user.company_projects_ar || '',
        company_collaboration_ar: user.company_collaboration_ar || '',
        company_commitments_ar: user.company_commitments_ar || '',
        company_challenges_ar: user.company_challenges_ar || '',
        is_active: true,
      });
      setExtraInfo(arrayresult);
    }
  }, [user])

  useEffect(async () => {
    const userID = localStorage.getItem('id');
    const profileId = localStorage.getItem('profile_id');
    try{
      const response = await dispatch(fetchProfileById(profileId));
      console.log("🚀 ~ useEffect ~ response:", response)
    }
    catch(error){
      console.error('Failed to fetch user profile:', error);
    }
  }, [dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompanyData({ ...companyData, [name]: value });
  };

  const handleSubmit = async (e) => {
    console.log("language in handlesubmit", language);
    const objectresult = extraInfo.reduce((acc, current) => {
      acc[current.question] = current.answer;
      return acc;
    }, {});
    setCompanyData(prevData => ({
      ...prevData,
      extra_info: objectresult
    }));

    // const arrayresult = Object.entries(objectresult).map(([key, value]) => ({
    //   question: key,
    //   answer: value
    // }));
    // console.log("🚀 ~ handleSubmit ~ arrayresult:", arrayresult)

    e.preventDefault();
    try {
      const profileId = localStorage.getItem('profile_id');
      const companyId = localStorage.getItem('company_id');
      const response = await dispatch(updateCompanyProfile({ ...companyData, extra_info: objectresult, id: profileId, company: companyId }));  
      toast({
        title: 'Success!',
        description: 'Profile has been updated.',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });  
    } catch (error) {
      console.error('Failed to fetch user profile:', error);
      toast({
        title: 'Error!',
        description: 'Failed to update profile. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      })
    }
    
  };

  const handleSubmitModal = async () => {
    const newEntry = { question, answer };
    setExtraInfo([...extraInfo, newEntry]);
    setQuestion('');
    setAnswer('');
    onClose();

  };

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const newExtraInfo = extraInfo.map((item, i) =>
      i === index ? { ...item, [name]: value } : item
    );
    setExtraInfo(newExtraInfo);
  };

  // const toggleLanguage = () => {
  //   console.log("language --->>> ", language);
  //   setLanguage((prevLanguage) => (prevLanguage === 'en' ? 'ar' : 'en'));
  // };

  const handleDelete = (index) => {
    setExtraInfo(extraInfo.filter((_, i) => i !== index));
  };

  const handleCloseModal = () => {
    // Clear the states when closing the modal without saving
    setQuestion('');
    setAnswer('');
    onClose(); // Call the onClose prop to close the modal
  };

  return (
    <Box mx="auto" mt={20} p={6} borderWidth={1} borderRadius="lg" bg="white">
      {/* <Flex justify="space-between" align="center" mb={6}>
        <Heading as="h5" size="sm">
          {t.language}
        </Heading>
        <Box
          as="button"
          display="flex"
          alignItems="center"
          width="80px"
          height="40px"
          bg={language === 'en' ? 'gray.300' : 'blue.400'}
          borderRadius="20px"
          position="relative"
          onClick={toggleLanguage}
          transition="background-color 0.3s"
        >
          <Box
            width="30px"
            height="30px"
            bg="white"
            borderRadius="15px"
            position="absolute"
            top="5px"
            left={language === 'en' ? '5px' : '45px'}
            transition="left 0.3s"
          />
        </Box>
      </Flex> */}
      <form onSubmit={handleSubmit}>
        <FormControl mb={4}>
          <FormLabel>What are the company’s activities?</FormLabel>
          <Input name="activities" value={companyData.activities} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel textAlign="right" className={"arabic_font"}>ما هي أنشطة الشركة؟</FormLabel>
          <Input name="activities_ar" textAlign="right" value={companyData.activities_ar} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>When was the company founded?</FormLabel>
          <Input name="foundation_details" placeholder='Asking for the year and how it started.' value={companyData.foundation_details} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel textAlign="right" className={"arabic_font"}>متى تأسست الشركة؟</FormLabel>
          <Input name="foundation_details_ar" textAlign="right" className={"arabic_font"} placeholder='السؤال عن السنة وكيف بدأت' value={companyData.foundation_details_ar} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>Who are the founders and key personnel?</FormLabel>
          <Textarea name="founders" value={companyData.founders} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel textAlign={"right"} className={"arabic_font"}>من هم المؤسسون والعاملون الرئيسيون؟</FormLabel>
          <Textarea name="founders_ar" textAlign={"right"} value={companyData.founders_ar} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>What are the company’s core values, vision, and mission?</FormLabel>
          <Textarea name="company_mission" value={companyData.company_mission} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel textAlign={"right"} className={"arabic_font"}>ما هي القيم الأساسية للشركة ورؤيتها ورسالتها؟</FormLabel>
          <Textarea name="company_mission_ar" textAlign={"right"} value={companyData.company_mission_ar} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>What are the company’s annual revenues and profits?</FormLabel>
          <Input name="company_revenue" value={companyData.company_revenue} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel textAlign={"right"} className={"arabic_font"}>ما هي إيرادات وأرباح الشركة السنوية؟</FormLabel>
          <Input name="company_revenue_ar" textAlign={"right"} value={companyData.company_revenue_ar} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>Does the company hold any certificates or awards?</FormLabel>
          <Textarea name="company_certificates" value={companyData.company_certificates} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel textAlign={"right"} className={"arabic_font"}>هل تحمل الشركة أي شهادات أو جوائز؟</FormLabel>
          <Textarea name="company_certificates_ar" textAlign={"right"} value={companyData.company_certificates_ar} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>What are the latest innovations and projects the company is working on?</FormLabel>
          <Textarea name="company_projects" value={companyData.company_projects} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4} >
          <FormLabel textAlign={"right"} className={"arabic_font"}>ما هي أحدث الابتكارات والمشاريع التي تعمل عليها الشركة؟</FormLabel>
          <Textarea name="company_projects_ar" textAlign={"right"} value={companyData.company_projects_ar} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>What are the company’s strategic partnerships and collaborations?</FormLabel>
          <Textarea name="company_collaboration" value={companyData.company_collaboration} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel textAlign={"right"} className={"arabic_font"}>ما هي الشراكات والتعاون الاستراتيجي للشركة؟</FormLabel>
          <Textarea name="company_collaboration_ar" textAlign={"right"} value={companyData.company_collaboration_ar} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>What are the company’s commitments to environmental and social issues?</FormLabel>
          <Textarea name="company_commitments" value={companyData.company_commitments} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel textAlign={"right"} className={"arabic_font"}>ما هي التزامات الشركة تجاه القضايا البيئية والاجتماعية؟</FormLabel>
          <Textarea name="company_commitments_ar" textAlign={"right"} value={companyData.company_commitments_ar} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>Are there any current challenges or issues facing the company?</FormLabel>
          <Textarea name="company_challenges" value={companyData.company_challenges} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel textAlign={"right"} className={"arabic_font"}>هل هناك أي تحديات أو مشاكل حالية تواجه الشركة؟</FormLabel>
          <Textarea name="company_challenges_ar" textAlign={"right"} value={companyData.company_challenges_ar} onChange={handleChange} maxLength={250} />
        </FormControl>
        {extraInfo.map((item, index) => (
           <Box key={index} mb={4}>
           <Box display="flex" alignItems="center">
             <FormLabel 
             className={"arabic_font"}
             textAlign={language === 'ar' ? 'right' : 'left'} flex="1"
             >
               {item.question}
             </FormLabel>
             <IconButton
               icon={<DeleteIcon />}
               onClick={() => handleDelete(index)}
               colorScheme="red"
               aria-label="Delete question"
               size="sm" // Make the icon smaller
               ml={2}
             />
           </Box>
           <Textarea
           className={"arabic_font"}
             name="answer"
             value={item.answer}
             onChange={(e) => handleInputChange(index, e)}
             textAlign={language === 'ar' ? 'right' : 'left'}
             maxLength={250}
             mt={2} // Add some margin to the top of the textarea
           />
         </Box>
        ))}
        <HStack display={{ sm: "none", lg: "flex" }} spacing='12px' mt={4}>
          <Button type="submit" ml={1} 
          bg="#EF4959" 
          color="white" 
          _hover={{ bg: "#E43D50" }}
          className={language === 'ar' && "arabic_font"}
          >{translate("btn-save")}</Button>
          <Button onClick={onOpen} mr={1.5} 
          bg="#F69320" 
          color="white" 
          _hover={{ bg: "#F67332" }}
          className={language === 'ar' && "arabic_font"}
          >{translate("add-question")}</Button>
        </HStack>
      </form>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader 
          style={language === 'ar' ? { textAlign: 'right', marginRight: '25px' } : {}}
          className={language === 'ar' && "arabic_font"}>{t.addQuestion}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <Flex justify="space-between" align="center" mb={6}>
              <Heading as="h5" size="sm" className={language === 'ar' && "arabic_font"}>
                {t.language}
              </Heading>
              <Switch
              name="is_active"
              isChecked={language === 'ar' ? true : false}
              onChange={toggleLanguage}
            />
            </Flex> */}
            <FormControl id="question" textAlign={language === 'ar' ? 'right' : 'left'}>
              <FormLabel textAlign={language === 'ar' ? 'right' : 'left'} className={language === 'ar' && "arabic_font"}>{t.question}</FormLabel>
              <Input
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                placeholder={t.enterQuestion}
                textAlign={language === 'ar' ? 'right' : 'left'}
                maxLength={250}
                className={language === 'ar' && "arabic_font"}
              />
            </FormControl>
            <FormControl id="answer" mt={4} textAlign={language === 'ar' ? 'right' : 'left'}>
              <FormLabel className={language === 'ar' && "arabic_font"} textAlign={language === 'ar' ? 'right' : 'left'}>{t.answer}</FormLabel>
              <Textarea
              className={language === 'ar' && "arabic_font"}
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
                placeholder={t.enterAnswer}
                textAlign={language === 'ar' ? 'right' : 'left'}
                maxLength={250}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button bg="#EF4959" 
        color="white" 
        _hover={{ bg: "#E43D50" }} mr={3} onClick={handleSubmitModal}
        disabled={!question ||!answer}
        className={language === 'ar' && "arabic_font"}
        >
              {t.save}
            </Button>
            <Button variant="ghost" className={language === 'ar' && "arabic_font"} onClick={handleCloseModal}>{t.cancel}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </Box>
  );
};

export default CompanyInfoSection;
