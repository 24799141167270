import axiosInstance from '../axiosConfig';

export const fetchTutorial = () => {
    return (dispatch) => {
        dispatch({
            type: 'FETCH_TUTORIAL_REQUEST'
        });
        return axiosInstance.get('/common/tutorials/')
            .then(response => {
                const tutorials = response.data;
                console.log("🚀 ~ return ~ tutorials:", tutorials)
                dispatch({
                    type: 'FETCH_TUTORIAL_SUCCESS',
                    payload: tutorials
                });
                return tutorials;
            })
            .catch(error => {
                const errorMsg = error.message;
                dispatch({
                    type: 'FETCH_TUTORIAL_FAILURE',
                    payload: errorMsg,
                });
                throw error;
            });
    };
};

export const uploadTutorial = (formData) => {
    return (dispatch) => {
        dispatch({
            type: 'UPLOAD_TUTORIAL_REQUEST'
        });
        return axiosInstance.post('/common/tutorials/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
              },
        })
            .then(response => {
                const tutorialVideo = response.data;
                dispatch({
                    type: 'UPLOAD_TUTORIAL_SUCCESS',
                    payload: tutorialVideo
                });
                dispatch(fetchTutorial());
                return tutorialVideo;
            })
            .catch(error => {
                const errorMsg = error.message;
                dispatch({
                    type: 'UPLOAD_TUTORIAL_FAILURE',
                    payload: errorMsg,
                });
                throw error;
            });
    };
};