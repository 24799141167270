import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createAvatar } from 'actions/avatarActions';

const Settings = () => {
  const iframeRef = useRef(null);
  const subdomain = 'test-application-buv76w'; // Replace with your custom subdomain
  const dispatch = useDispatch();

  const [avatarDetails, setAvatarDetails] = useState({});

  useEffect(() => {
    Object.keys(avatarDetails).length > 0 && dispatch(createAvatar(avatarDetails));
  }, [avatarDetails]);

  useEffect(() => {
    const frame = iframeRef.current;
    frame.src = `https://${subdomain}.readyplayer.me/avatar?frameApi`;

    const subscribe = (event) => {
      const json = parse(event);

      if (json?.source !== 'readyplayerme') {
        return;
      }

      if (json.eventName === 'v1.frame.ready') {
        frame.contentWindow.postMessage(
          JSON.stringify({
            target: 'readyplayerme',
            type: 'subscribe',
            eventName: 'v1.**',
          }),
          '*'
        );
      }

      if (json.eventName === 'v1.avatar.exported') {
        console.log(`Avatar URL: ${json.data.url}`);
        console.log(`Avatar URL: ${json.data.avatarId}`);
        const avatar_url = `${json.data.url}`;
        const avatar_id = `${json.data.avatarId}`;
        document.getElementById('avatarUrl').innerHTML = `Avatar URL: ${json.data.url}`;
        setAvatarDetails({
          avatar_url,
          avatar_id,
        })
        // frame.hidden = true;
      }

      if (json.eventName === 'v1.user.set') {
        console.log(`User with id ${json.data.id} set: ${JSON.stringify(json)}`);
      }
    };

    window.addEventListener('message', subscribe);
    document.addEventListener('message', subscribe);

    return () => {
      window.removeEventListener('message', subscribe);
      document.removeEventListener('message', subscribe);
    };
  }, [subdomain]);

  const parse = (event) => {
    try {
      return JSON.parse(event.data);
    } catch (error) {
      return null;
    }
  };

  const displayIframe = useCallback(() => {
    iframeRef.current.hidden = false;
  }, []);

  return (
    <div style={{ width: '1080px', height: '680px', marginTop: '80px', margin: '0', padding: '20px', fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif', fontSize: '14px', border: 'none' }}>
      {/* <h2>Ready Player Me iframe example</h2>
      <ul>
        <li>Click the "Open Ready Player Me" button.</li>
        <li>Create an avatar and click the "Done" button when you're done customizing.</li>
        <li>After creation, this parent page receives the URL to the avatar.</li>
        <li>The Ready Player Me window closes and the URL is displayed.</li>
      </ul>
      <p className="warning" style={{ backgroundColor: '#df68a2', padding: '3px', borderRadius: '5px', color: 'white' }}>
        If you have a subdomain, replace the 'demo' subdomain in the iframe source URL with yours.
      </p>

      <input type="button" value="Open Ready Player Me" onClick={displayIframe} /> */}
      <p id="avatarUrl">Avatar URL:</p>

      <iframe
        ref={iframeRef}
        className="frame"
        style={{ width: '1080px', height: '680px', border: 'none' }}
        allow="camera *; microphone *; clipboard-write"
      ></iframe>
    </div>
  );
};

export default Settings;
