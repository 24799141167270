// reducers/userReducer.js
const initialState = {
    loading: false,
    avatar: [],
    characterDetail: [],
    list_accents: [],
    error: ''
  };
  
  const userReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_AVATAR_REQUEST':
        return {
          ...state,
          loading: true
        };
      case 'FETCH_AVATAR_SUCCESS':
        return {
          ...state,
          loading: false,
          avatar: action.payload,
          error: ''
        };
      case 'FETCH_AVATAR_FAILURE':
        return {
          ...state,
          loading: false,
          avatar: [],
          error: action.payload
        };
        case 'FETCH_CONVAI_REQUEST':
        return {
          ...state,
          loading: true
        };
      case 'FETCH_CONVAI_SUCCESS':
        return {
          ...state,
          loading: false,
          characterDetail: action.payload,
          error: ''
        };
      case 'FETCH_CONVAI_FAILURE':
        return {
          ...state,
          loading: false,
          characterDetail: [],
          error: action.payload
        };
        case 'FETCH_CONVAI_ACCENTS_LIST':
          return {
            ...state,
            loading: true
          };
        case 'FETCH_CONVAI_ACCENTS_LIST_SUCCESS':
          return {
            ...state,
            loading: false,
            list_accents: action.payload,
            error: ''
          };
        case 'FETCH_CONVAI_ACCENTS_LIST_FAILURE':
          return {
            ...state,
            loading: false,
            list_accents: [],
            error: action.payload
          };
          case 'CREATE_CONVAI_REQUEST':
        return {
          ...state,
          loading: true
        };
      case 'CREATE_CONVAI_SUCCESS':
        return {
          ...state,
          loading: false,
          error: ''
        };
      case 'CREATE_CONVAI_FAILURE':
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
  
  export default userReducer;
  