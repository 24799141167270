// index.js
import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { AuthProvider } from 'views/auth/signIn/AuthContext'; // Import the AuthProvider
import ProtectedRoute from 'views/auth/signIn/ProtectedRoute'; // Import the ProtectedRoute
import { Provider } from 'react-redux';
import store from 'store';
import { CompanyProvider } from 'context/CompanyContext'; // Import the CompanyProvider
import { LanguageProvider } from "context/LanguageContext";

ReactDOM.render(
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      <React.StrictMode>
        <AuthProvider>
          <CompanyProvider> {/* Add CompanyProvider here */}
          <LanguageProvider>
            <HashRouter>
              <Switch>
                <Route path={`/auth`} component={AuthLayout} />
                <ProtectedRoute path={`/admin`} component={AdminLayout} />
                <Redirect from='/' to='/auth/sign-in' />
              </Switch>
            </HashRouter>
            </LanguageProvider>
          </CompanyProvider>
        </AuthProvider>
      </React.StrictMode>
    </ChakraProvider>
  </Provider>,
  document.getElementById('root')
);
