import axiosInstance from '../axiosConfig';

export const fetchNarrative = () => {
    return (dispatch) => {
        dispatch({
            type: 'FETCH_NARRATIVE_REQUEST'
        });
        return axiosInstance.get('/avatars/convai/get_character_narrative/')
            .then(response => {
                const narrativeData = response.data;
                console.log("🚀 ~ return ~ narrativeData:", narrativeData)
                dispatch({
                    type: 'FETCH_NARRATIVE_SUCCESS',
                    payload: narrativeData
                });
                return narrativeData;
            })
            .catch(error => {
                const errorMsg = error.message;
                dispatch({
                    type: 'FETCH_NARRATIVE_FAILURE',
                    payload: errorMsg,
                });
                throw error;
            });
    };
};

export const createNarrative = (narrativeData) => {
    return (dispatch) => {
        dispatch({
            type: 'CREATE_NARRATIVE_REQUEST'
        });
        return axiosInstance.post('/avatars/convai/create_character_narrative/', narrativeData)
            .then(response => {
                const responseNarrative = response.data;
                console.log("🚀 ~ return ~ responseNarrative:", response)
                dispatch({
                    type: 'CREATE_NARRATIVE_SUCCESS',
                    payload: responseNarrative?.narrative
                });
                dispatch(fetchNarrative());
                return responseNarrative;
            })
            .catch(error => {
                const errorMsg = error.message;
                dispatch({
                    type: 'CREATE_NARRATIVE_FAILURE',
                    payload: errorMsg,
                });
                throw error;
            });
    };
};

export const deleteNarrativeNode = (id) => {
    return (dispatch) => {
        dispatch({
            type: 'DELETE_NARRATIVE_REQUEST',
        });
        return axiosInstance.post(`/avatars/convai/delete_narrative_node/`, {narrative_node_id: id})
            .then(response => {
                const responseNarrative = response.data;
                dispatch({
                    type: 'DELETE_NARRATIVE_SUCCESS',
                    payload: responseNarrative
                });
                // dispatch(fetchNarrative());
                return responseNarrative;
            })
            .catch(error => {
                const errorMsg = error.message;
                dispatch({
                    type: 'DELETE_NARRATIVE_FAILURE',
                    payload: errorMsg
                });
                throw error;
            });
    };
};