
// reducers/userReducer.js
const initialState = {
    loading: false,
    companyProfiles: [],
    error: '',
    singleProfile: {},
};

const companyProfilesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_PROFILES_REQUEST':
            return {
                ...state,
                loading: true
            };
        case 'FETCH_PROFILES_SUCCESS':
            return {
                ...state,
                loading: false,
                companyProfiles: action.payload,
                error: ''
            };
        case 'FETCH_PROFILES_FAILURE':
            return {
                ...state,
                loading: false,
                companyProfiles: [],
                error: action.payload
            };
            case 'FETCH_SINGLE_PROFILE_REQUEST':
            return {
                ...state,
                loading: true
            };
        case 'FETCH_SINGLE_PROFILE_SUCCESS':
            return {
                ...state,
                loading: false,
                singleProfile: action.payload,
                error: ''
            };
        case 'FETCH_SINGLE_PROFILE_FAILURE':
            return {
                ...state,
                loading: false,
                singleProfile: {},
                error: action.payload
            };
        case 'CREATE_KNOWLEDGE_BANK_REQUEST':
            return {
                ...state,
                loading: true
            };
        case 'CREATE_KNOWLEDGE_BANK_SUCCESS':
            return {
                ...state,
                loading: false,
                error: ''
            };
        case 'CREATE_KNOWLEDGE_BANK_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case 'UPDATE_COMPANY_PROFILE_REQUEST':
            return {
                ...state,
                loading: true
            };
        case 'UPDATE_COMPANY_PROFILE_SUCCESS':
            return {
                ...state,
                loading: false,
                error: ''
            };
        case 'UPDATE_COMPANY_PROFILE_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.payload
            };
            case 'DELETE_COMPANY_PROFILE_REQUEST':
            return {
                ...state,
                loading: true
            };
        case 'DELETE_COMPANY_PROFILE_SUCCESS':
            return {
                ...state,
                loading: false,
                error: ''
            };
        case 'DELETE_COMPANY_PROFILE_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

export default companyProfilesReducer;
