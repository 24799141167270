import React, { useContext} from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
} from '@chakra-ui/react';
import { LanguageContext } from 'context/LanguageContext';

const ConfirmationModal = ({ isOpen, onClose, onConfirm, title, message }) => {
  const { translate, language } = useContext(LanguageContext);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader className={language === 'ar' && "arabic_font"}>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text className={language === 'ar' && "arabic_font"}>{message}</Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={onConfirm} className={language === 'ar' && "arabic_font"}>
            {translate('btn-confirm')}
          </Button>
          <Button variant="ghost" onClick={onClose} className={language === 'ar' && "arabic_font"}>
            {translate('txt-comp-cancel')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModal;
