import React, { useEffect } from 'react';
import { lineSpinner } from 'ldrs';

const LineSpinnerComponent = () => {
  useEffect(() => {
    lineSpinner.register();
  }, []);

  return (
    <div>
      <l-line-spinner
        size="100"
        stroke="3"
        speed="1"
        color="black"
      ></l-line-spinner>
    </div>
  );
};

export default LineSpinnerComponent; 