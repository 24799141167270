import React, { useState } from 'react';
import { Box, Button, Flex, FormControl, FormLabel, Input, VStack, Heading, Textarea } from "@chakra-ui/react";



const StepThree = ({ formData, setFormData, prevStep, nextStep }) => (
    <VStack spacing={4}>
        <Heading as="h2" size="lg" textAlign="center" mb={6}>
        Company Info
      </Heading>
      <FormControl>
        <FormLabel>Tell us about your company</FormLabel>
        <Textarea
          value={formData.companyInfo}
          placeholder='Long Paragraph field'
          onChange={(e) => setFormData({ ...formData, companyInfo: e.target.value })}
        />
      </FormControl>
      <Flex>
        <Button onClick={prevStep} mr={2} bg="gray.500" color="gray.100" _hover={{ bg: "gray.700" }}>Back</Button>
        <Button onClick={nextStep} colorScheme="blue">Next</Button>
      </Flex>
    </VStack>
  );

  export default StepThree;