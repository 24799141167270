// reducers/userReducer.js
const initialState = {
    loading: false,
    generalKnowledgeBank: [],
    error: ''
};

const generalKnowledgeBank = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_GENERAL_KNOWLEDGE_BANK_REQUEST':
            return {
               ...state,
                loading: true
            };
        case 'FETCH_GENERAL_KNOWLEDGE_BANK_SUCCESS':
            return {
               ...state,
                loading: false,
                generalKnowledgeBank: action.payload,
                error: ''
            };
        case 'FETCH_GENERAL_KNOWLEDGE_BANK_FAILURE':
            return {
               ...state,
                loading: false,
                generalKnowledgeBank: [],
                error: action.payload
            };
        default:
            return state;
    }
}

export default generalKnowledgeBank
