import React, { useState, useContext } from "react";
import {
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  TableContainer,
  useColorModeValue,
  Button,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  VStack,
  useToast,
  Select,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import Card from "components/card/Card";
import { updateUser, fetchUserById, deleteUser } from 'actions/userActions';
import { useDispatch } from "react-redux";
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import { LanguageContext } from "context/LanguageContext";
import { format, addDays } from 'date-fns';

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  padding: 8px 12px;  /* Ensure this matches the padding of the select input */
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  background-color: white;
  font-size: 1rem;
  color: #4a5568;
  box-sizing: border-box; /* This ensures the padding is included within the width */
  
  &:focus {
    outline: none;
    border-color: #3182ce;
    box-shadow: 0 0 0 1px #3182ce;
  }

  &::placeholder {
    color: #a0aec0;
  }
`;

const ColumnsTable = ({ columnsData, tableData, isCompanyTable }) => {
  const { translate, language } = useContext(LanguageContext);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const dispatch = useDispatch();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedRow, setSelectedRow] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [formData, setFormData] = useState({subscription_time: ""});
  const [subscriptionTime, setSubscriptionTime] = useState(addDays(new Date(), 1));
  const [subscriptionType, setSubscriptionType] = useState();
  const toast = useToast();

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log({name, value}, "handleChange");
    
    setFormData({ ...formData, [name]: value });
  };

  const handleEdit = (row) => {
    setFormData(row);
    console.log("🚀 ~ handleEdit ~ row:", row)
    setSubscriptionTime(row?.subscription_time)
    setSelectedRow(row);
    setIsEditMode(true);
    onOpen();
  };

  const handleDelete = (row) => {
    setSelectedRow(row);
    setIsEditMode(false);
    onOpen();
  };

  const handleSave = () => {
    // Handle saving the form data
    dispatch(updateUser(selectedRow?.id, {...formData, subscription_time: subscriptionTime}, toast));
    dispatch(fetchUserById(selectedRow?.id));
    onClose();
  };

  const handleConfirmDelete = () => {
    // Handle deleting the selected row
    dispatch(deleteUser(selectedRow?.id, toast));
    onClose();
  };

  const renderFormFields = () => {
    if (isCompanyTable) {
      // Fields for editing a company
      return (
        <VStack spacing={4}>
          <FormControl id="company_name">
            <FormLabel className={language === 'ar' && "arabic_font"}>{translate("txt-comp-name")}</FormLabel>
            <Input
              type="text"
              name="company_name"
              value={formData.company_name || ''}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl id="website">
            <FormLabel className={language === 'ar' && "arabic_font"}>{translate("txt-comp-web")}</FormLabel>
            <Input
              type="url"
              name="website"
              value={formData.website || ''}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl id="website_ar">
            <FormLabel className={language === 'ar' && "arabic_font"}>{translate("txt-comp-web-ar")}</FormLabel>
            <Input
              type="url"
              name="website_ar"
              value={formData.website_ar || ''}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl id="size">
            <FormLabel className={language === 'ar' && "arabic_font"}>{translate("txt-comp-size")}</FormLabel>
            <Input
              type="text"
              name="size"
              value={formData.size || ''}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl mt={4} >
                <FormLabel display="flex" className={language === 'ar' && "arabic_font"}>{translate("txt-comp-sub-type")}</FormLabel>
                <Select
                  name="subscription_type"
                  placeholder="Select subscription type"
                  value={formData.subscription_type || ''}
                  onChange={handleChange}
                >
                  <option value="monthly">Monthly</option>
                  <option value="yearly">Yearly</option>
                </Select>
              </FormControl>

              <FormControl mt={4} >
                <FormLabel display="flex" className={language === 'ar' && "arabic_font"}>
                {translate("txt-comp-sub-time")}
                </FormLabel>
                <StyledDatePicker
                  name="subscription_type"
                  selected={subscriptionTime}
                  onChange={(date) => setSubscriptionTime(format(date, 'yyyy-MM-dd'))}
                  minDate={addDays(new Date(), 1)} // Disables today's and past dates
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Select subscription time"
                />
              </FormControl>

        </VStack>
      );
    } else {
      // Fields for editing a company account
      return (
        <VStack spacing={4}>
          <FormControl id="email">
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              name="email"
              value={formData.email || ''}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl id="accountManager">
            <FormLabel>Account Manager</FormLabel>
            <Input
              type="text"
              name="accountManager"
              value={formData.accountManager || ''}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl id="password">
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              name="password"
              value={formData.password || ''}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl id="contactNumber">
            <FormLabel>Contact Number</FormLabel>
            <Input
              type="tel"
              name="contactNumber"
              value={formData.contactNumber || ''}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl id="covaiKey">
            <FormLabel>Covai Key</FormLabel>
            <Input
              type="text"
              name="covaiKey"
              value={formData.covaiKey || ''}
              onChange={handleChange}
            />
          </FormControl>
         

          {/* <FormControl id="companyProfile">
            <FormLabel>Company Profile</FormLabel>
            <Select
              placeholder="Select Company"
              name="companyProfile"
              value={formData.companyProfile || ''}
              onChange={handleChange}
            >
              {tableData.map((company) => (
                <option key={company.id} value={company.id}>
                  {company.company_name}
                </option>
              ))}
            </Select>
          </FormControl> */}
        </VStack>
      );
    }
  };

  return (
    <Card direction='column' w='100%' px='0px' overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%' className={language === 'ar' && "arabic_font"}>
          {isCompanyTable ? translate(("table-header-companies")) : "Company Accounts"}
        </Text>
      </Flex>
      <TableContainer>
        <Table variant="striped">
          <Thead>
            <Tr>
              {columnsData.map((column, index) => (
                <Th key={index} fontWeight="bold" fontSize="sm" color="black.600" textAlign="left" 
                className={language === 'ar' ? "arabic_font" : ""}
                >
                  {column.Header}
                </Th>
              ))}
              <Th fontWeight="bold" fontSize="sm" color="black.600" textAlign="left" className={language === 'ar' && "arabic_font"}>{translate(("column-header-action"))}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {tableData && tableData.map((row, rowIndex) => (
              <Tr key={rowIndex}>
                {columnsData.map((column, colIndex) => (
                  <Td key={colIndex} className={language === 'ar' && "arabic_font"} textTransform={column.accessor === 'subscription_type' && "capitalize"}>{row[column.accessor] || 'N/A'} </Td>
                ))}
                <Td>
                  <IconButton
                    icon={<EditIcon />}
                    onClick={() => handleEdit(row)}
                    mr={2}
                  />
                  <IconButton
                    icon={<DeleteIcon />}
                    onClick={() => handleDelete(row)}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className={language === 'ar' && "arabic_font"}>{isEditMode ? (isCompanyTable ? translate("txt-edit-company") : "Edit Company Account") : translate("txt-delete-confirmation")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isEditMode ? (
              renderFormFields()
            ) : (
              <Text className={language === 'ar' && "arabic_font"}>{translate("txt-delete")}</Text>
            )}
          </ModalBody>

          <ModalFooter>
            {isEditMode ? (
              <Button
                bg="#F69320"
                color="white"
                _hover={{ bg: "#F67332" }}
                onClick={handleSave}
                className={language === 'ar' && "arabic_font"}
                >
                {translate("btn-save")}
              </Button>
            ) : (
              <Button colorScheme="red" mr={3} onClick={handleConfirmDelete} className={language === 'ar' && "arabic_font"}>
                {translate("btn-delete")}
              </Button>
            )}
            <Button variant="ghost" onClick={onClose} className={language === 'ar' && "arabic_font"}>{translate("txt-comp-cancel")}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Card>
  );
};

export default ColumnsTable;
