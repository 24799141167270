import axiosInstance from '../axiosConfig';

export const fetchKnowledgeBankRequest = () => {
    return {
        type: 'FETCH_KNOWLEDGE_BANK_REQUEST'
    };
};

export const fetchKnowledgeBankSuccess = (knowledgeBank) => {
    return {
        type: 'FETCH_KNOWLEDGE_BANK_SUCCESS',
        payload: knowledgeBank
    };
};

export const fetchKnowledgeBankFailure = (error) => {
    return {
        type: 'FETCH_KNOWLEDGE_BANK_FAILURE',
        payload: error
    };
};

export const createKnowledgeBankRequest = () => {
    return {
        type: 'CREATE_KNOWLEDGE_BANK_REQUEST'
    };
};

export const createKnowledgeBankSuccess = (knowledgeBank) => {
    return {
        type: 'CREATE_KNOWLEDGE_BANK_SUCCESS',
        payload: knowledgeBank
    };
};

export const createKnowledgeBankFailure = (error) => {
    return {
        type: 'CREATE_KNOWLEDGE_BANK_FAILURE',
        payload: error
    };
};

export const fetchcheckDocumentStatusRequest = () => {
    return {
        type: 'FETCH_CHECK_DOCUMENT_REQUEST'
    };
};

export const fetchcheckDocumentStatusSuccess = (isAvailable) => {
    return {
        type: 'FETCH_CHECK_DOCUMENT_SUCCESS',
        payload: isAvailable
    };
};

export const fetchcheckDocumentStatusFailure = (error) => {
    return {
        type: 'FETCH_CHECK_DOCUMENT_FAILURE',
        payload: error
    };
};

export const connectKnowledgeBank = () => {
    return (dispatch) => {
        axiosInstance.get('/avatars/questions/connect_knowledge_bank/')
            .then(response => {
                const isAvailable = response.data;
            })
            .catch(error => {
                const errorMsg = error.message;
            });
    };
};

export const checkDocumentStatus = () => {
    return (dispatch) => {
        dispatch(fetchcheckDocumentStatusRequest());
        axiosInstance.get('/avatars/questions/check_document_status/')
            .then(response => {
                const isAvailable = response.data;
                dispatch(fetchcheckDocumentStatusSuccess(isAvailable));
            })
            .catch(error => {
                const errorMsg = error.message;
                dispatch(fetchcheckDocumentStatusFailure(errorMsg));
            });
    };
};

export const fetchKnowledgeBank = () => {
    return (dispatch) => {
        dispatch(fetchKnowledgeBankRequest());
        axiosInstance.get('/avatars/questions/')
            .then(response => {
                const knowledgeBank = response.data;
                dispatch(fetchKnowledgeBankSuccess(knowledgeBank));
            })
            .catch(error => {
                const errorMsg = error.message;
                dispatch(fetchKnowledgeBankFailure(errorMsg));
            });
    };
};

export const createKnowledgeBank = (knowledgeBankDetails, toast) => {
    return (dispatch) => {
        dispatch(createKnowledgeBankRequest());
        axiosInstance.post('/avatars/questions/bulk_create/', knowledgeBankDetails)
            .then(response => {
                const knowledgeBank = response.data;
                dispatch(createKnowledgeBankSuccess(knowledgeBank));
                dispatch(fetchKnowledgeBank());
                dispatch(checkDocumentStatus());
                toast({
                    title: 'Data updated successfully!',
                    status: "success",
                    isClosable: true,
                    position: 'top',
                })
            })
            .catch(error => {
                const errorMsg = error.message;
                dispatch(createKnowledgeBankFailure(errorMsg));
                toast({
                    title: 'Something went wrong!',
                    status: "error",
                    isClosable: true,
                    position: 'top',
                })
            });
    };
};
