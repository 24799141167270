// src/axiosConfig.js
import axios from 'axios';

// Create an instance of axios
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});

// Set the AUTH token for any request
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // or wherever you store your token
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
