
// reducers/userReducer.js
const initialState = {
    loading: false,
    companyAccounts: [],
    singleCompanyAccount: [],
    myAccountInfo: {},
    error: '',
};

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CREATE_ACCOUNT_REQUEST':
            return {
                ...state,
                loading: true
            };
        case 'CREATE_ACCOUNT_SUCCESS':
            return {
                ...state,
                loading: false,
                error: ''
            };
        case 'CREATE_ACCOUNT_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case 'FETCH_ACCOUNTS_REQUEST':
            return {
                ...state,
                loading: true
            };
        case 'FETCH_ACCOUNTS_SUCCESS':
            return {
                loading: false,
                companyAccounts: action.payload,
                error: ''
            };
        case 'FETCH_ACCOUNTS_FAILURE':
            return {
                loading: false,
                companyAccounts: [],
                error: action.payload
            };
        case 'FETCH_SINGLE_ACCOUNT_SUCCESS':
            return {
                loading: false,
                singleCompanyAccount: action.payload,
                error: ''
            };
        case 'FETCH_SINGLE_ACCOUNT_FAILURE':
            return {
                loading: false,
                singleCompanyAccount: [],
                error: action.payload
            };
        case 'FETCH_MY_ACCOUNT_INFO_REQUEST':
            return {
                ...state,
                loading: true
            };
        case 'FETCH_MY_ACCOUNT_INFO_SUCCESS':
            return {
                loading: false,
                myAccountInfo: action.payload,
                error: ''
            };
        case 'FETCH_MY_ACCOUNT_INFO_FAILURE':
            return {
                loading: false,
                companyAccounts: [],
                error: action.payload
            };
        default:
            return state;
    }
};

export default accountReducer;
