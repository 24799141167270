import React, { useState } from "react";
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue,
    useToast,
} from "@chakra-ui/react";
import { NavLink, useHistory } from "react-router-dom";
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import axiosInstance from '../../../axiosConfig';

const ResetPassword = () => {
    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "gray.400";
    const textColorBrand = useColorModeValue("brand.500", "white");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    const toast = useToast();
    const history = useHistory();

    const [step, setStep] = useState(1);
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [show, setShow] = useState(false);
    const [confirmShowPassword, setConfirmShowPassword] = useState(false);
    const [token, setToken] = useState('');

    const handleClick = () => setShow(!show);
    const handleConfirmClick = () => setConfirmShowPassword(!confirmShowPassword);

    const handleResponse = (response, successMessage, nextStep) => {
        if (response?.status === 200) { // Check if the response status is 200 (OK)
            setStep(nextStep);
            toast({ title: successMessage, status: 'success', duration: 3000, isClosable: true, position: 'top'});
        } else {
            toast({ title: 'An error occurred', status: 'error', duration: 3000, isClosable: true, position: 'top' });
        }
    };

    const handleSendOTP = async () => {
        try {
            const response = await axiosInstance.post('/accounts/reset-password/', { email });
            console.log("🚀 ~ return ~ response:", response.data);
            handleResponse(response, 'OTP sent to your email', 2);
        } catch (error) {
            console.log("🚀 ~ return ~ error:", error?.response?.data?.detail);
            toast({ title: error?.response?.data?.detail, status: 'error', duration: 3000, isClosable: true, position: 'top' });
        }
    };


    const handleVerifyOTP = async () => {
        try {
            const response = await axiosInstance.post('/accounts/verify-otp/', { email, otp });
            console.log("🚀 ~ return ~ response:", response.data);
            setToken(response.data?.token);
            handleResponse(response, 'OTP verified successfully.', 3);
        } catch (error) {
            console.log("🚀 ~ return ~ error:", error?.response?.data?.detail);
            toast({ title: error?.response?.data?.detail , status: 'error', duration: 3000, isClosable: true, position: 'top' });
        }
    };

    const handleResetPassword = async () => {
        if(password !== confirmPassword){
            toast({ title: 'Passwod does not match', status: 'error', duration: 3000, isClosable: true, position: 'top' });
        }
        else{
            try {
                const response = await axiosInstance.post('/accounts/change-password/', { email, token, password });
                console.log("🚀 ~ return ~ response:", response.data);
                handleResponse(response, 'Password updated successfully.', 3);
                history.push('/auth/sign-in');
            } catch (error) {
                console.log("🚀 ~ return ~ error:", error?.response?.data?.password[0]);
                toast({ title: error?.response?.data?.password[0], status: 'error', duration: 3000, isClosable: true, position: 'top' });
            }
        }
        
    };

    const renderForm = (fields, buttonText, onSubmit) => (
        <FormControl onSubmit={onSubmit}>
            {fields.map(({ label, type, value, setValue, placeholder }) => (
                <div key={label}>
                    <FormLabel
                        display="flex"
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        mb="8px"
                    >
                        {label}
                        <Text color={brandStars}>*</Text>
                    </FormLabel>
                    <Input
                        isRequired
                        variant="auth"
                        fontSize="sm"
                        type={type}
                        placeholder={placeholder}
                        mb="24px"
                        fontWeight="500"
                        size="lg"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                    />
                </div>
            ))}
            {/* <Flex justifyContent="space-between" align="center" mb="24px">
                <NavLink to="/auth/sign-in">
                    <Text
                        color={textColorBrand}
                        fontSize="sm"
                        w="124px"
                        fontWeight="500"
                    >
                        Sign In
                    </Text>
                </NavLink>
            </Flex> */}
            {step === 3 && (
                <>
                    <FormLabel
                        display="flex"
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        mb="8px"
                    >
                        New Passsword
                        <Text color={brandStars}>*</Text>
                    </FormLabel>
                    <InputGroup size="md">
                        <Input
                            isRequired
                            fontSize="sm"
                            placeholder="Min. 8 characters"
                            mb="24px"
                            size="lg"
                            type={show ? "text" : "password"}
                            variant="auth"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <InputRightElement display="flex" alignItems="center" mt="4px">
                            <Icon
                                color={textColorSecondary}
                                _hover={{ cursor: "pointer" }}
                                as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                onClick={handleClick}
                            />
                        </InputRightElement>
                    </InputGroup>
                    <FormLabel
                        display="flex"
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        mb="8px"
                    >
                        Confirm Passsword
                        <Text color={brandStars}>*</Text>
                    </FormLabel>
                    <InputGroup size="md">
                        <Input
                            isRequired
                            fontSize="sm"
                            placeholder="Min. 8 characters"
                            mb="24px"
                            size="lg"
                            type={confirmShowPassword ? "text" : "password"}
                            variant="auth"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <InputRightElement display="flex" alignItems="center" mt="4px">
                            <Icon
                                color={textColorSecondary}
                                _hover={{ cursor: "pointer" }}
                                as={confirmShowPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                onClick={handleConfirmClick}
                            />
                        </InputRightElement>
                    </InputGroup>
                </>
            )}
            <Button
                bg="#F69320" 
                color="white" 
                _hover={{ bg: "#F67332"}}
                w="100%"
                h="50"
                mb="24px"
                onClick={onSubmit}
            >
                {buttonText}
            </Button>
        </FormControl>
    );

    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <Flex
                maxW={{ base: "100%", md: "max-content" }}
                w="100%"
                mx={{ base: "auto", lg: "0px" }}
                me="auto"
                h="100%"
                alignItems="start"
                justifyContent="center"
                mb={{ base: "30px", md: "60px" }}
                px={{ base: "25px", md: "0px" }}
                mt={{ base: "40px", md: "14vh" }}
                flexDirection="column"
            >
                <Box me="auto">
                    <Heading color={textColor} fontSize="36px" mb="10px">
                    {step === 1 && 'Forgot Password'}
                        {step === 2 && 'Verify OTP'}
                        {step === 3 && 'Reset Password'}
                    </Heading>
                    <Text
                        mb="36px"
                        ms="4px"
                        color={textColorSecondary}
                        fontWeight="400"
                        fontSize="md"
                    >
                        {step === 1 && 'Enter your email to get OTP!'}
                        {step === 2 && 'Enter your email and verify OTP sent on your email!'}
                        {step === 3 && 'Enter new password!'}
                    </Text>
                </Box>
                <Flex
                    zIndex="2"
                    direction="column"
                    w={{ base: "100%", md: "420px" }}
                    maxW="100%"
                    background="transparent"
                    borderRadius="15px"
                    mx={{ base: "auto", lg: "unset" }}
                    me="auto"
                    mb={{ base: "20px", md: "auto" }}
                >
                    <Flex align="center" mb="25px">
                        <HSeparator />
                    </Flex>
                    {step === 1 && renderForm(
                        [{ label: 'Email', type: 'email', value: email, setValue: setEmail, placeholder: 'mail@hologram.com' }],
                        'Send OTP',
                        handleSendOTP
                    )}
                    {step === 2 && renderForm(
                        [
                            { label: 'Email', type: 'email', value: email, setValue: setEmail, placeholder: 'mail@hologram.com' },
                            { label: 'OTP', type: 'text', value: otp, setValue: setOtp, placeholder: 'Enter OTP' }
                        ],
                        'Verify OTP',
                        handleVerifyOTP
                    )}
                    {step === 3 && renderForm(
                        [
                            { label: 'Email', type: 'email', value: email, setValue: setEmail, placeholder: 'mail@hologram.com' }
                        ],
                        'Reset Password',
                        handleResetPassword
                    )}
                </Flex>
            </Flex>
        </DefaultAuth>
    );
};

export default ResetPassword;
